import { Controller } from 'stimulus';
import { addDataAction, clearInput } from '../lib/utils';

/* ## How to use this Stimulus Controller ##
 * In all cases:
 *   add the data-controller="dynamic-form" attribute to the form element that contains dynamic inputs
 *   add the data-target="dynamic-form.input" attribute to the dynamic input
 *
 * To toggle an input's required state:
 *   add the data-require-default="true" attribute to the dynamic input
 *   add the data-require-override-condition="{'id-of-controlling-input': ['values-to-match'], 'id-of-other-controlling-input': ['other-values-to-match']}"
 *    Note 0: the value of the data-require-override-condition attribute is an object containing ids (keys) and arrays of string values (values)
 *    Note 1: this attribute is stored as a string on the element (as all element attributes are)
 *    Note 2: this string will be parsed using JSON.parse(), so it is important to include the curly braces and the quotes around all keys and string values
 *    Note 3: to avoid escaping your quotation marks you can use single quotes around keys and values, they will be converted to double quotes prior to parsing.
 *
 * To toggle an input's visibility the steps are similar to above but the attribute names are different:
 *   data-visibility-default="true" attribute to the dynamic input
 *   data-visibility-override-condition="{'id-of-controlling-input': ['values-to-match'], 'id-of-other-controlling-input': ['other-values-to-match']}"
 *
 * To toggle an attribute on an input based on the value of another element:
 *   data-set-attribute-default="true"
 *   data-set-attribute-override-condition="{'id-of-controlling-input': ['values-to-match']}"
 *   data-set-attribute-name-and-value="{'attribute-name-to-add': 'attribute-value-to-add'}"
 * 
 * To propagate a change event from a primary input to a secondary input:
 *   add the data-propagate-change-to-id="id-of-secondary-input" attribute to the primary input
 */

export default class extends Controller {
  static targets = ['input'];

  initialize() {
    if (!this.hasInputTarget) {
      return;
    }

    this.inputTargets.forEach(input => {
      const {
        requireOverrideCondition,
        visibilityOverrideCondition,
        setAttributeOverrideCondition,
        propagateChangeToId
      } = input.dataset;

      if (typeof requireOverrideCondition !== 'undefined') {
        this.addChangeHandlers(requireOverrideCondition);
      }

      if (typeof visibilityOverrideCondition !== 'undefined') {
        this.addChangeHandlers(visibilityOverrideCondition);
      }

      if (typeof setAttributeOverrideCondition !== 'undefined') {
        this.addChangeHandlers(setAttributeOverrideCondition)
      }

      if (typeof propagateChangeToId !== 'undefined') {
        addDataAction(input, 'change->dynamic-form#propagateChange');
      }
    });

    this.updateForm();
  }

  addChangeHandlers(condition) {
    const cleanOverrideCondition = condition.replace(/'/g, '"');
    const parsedOverrideCondition = JSON.parse(cleanOverrideCondition);
    Object.keys(parsedOverrideCondition).forEach(controlId => {
      if (!controlId) return;
      addDataAction(document.getElementById(controlId), 'change->dynamic-form#updateForm');
    });
  }

  updateForm() {
    this.inputTargets.forEach(input => {
      const {
        visibilityDefault,
        visibilityOverrideCondition,
        requireDefault,
        requireOverrideCondition,
        setAttributeDefault,
        setAttributeOverrideCondition,
        setAttributeNameAndValue
      } = input.dataset;

      // The data type for all dataset variables is string. The string 'false' will evaluate to true.
      const visibilityDefaultBool = visibilityDefault === 'true';

      if (typeof visibilityOverrideCondition !== 'undefined') {
        this.applyRules(
          input,
          visibilityOverrideCondition,
          visibilityDefaultBool,
          this.toggleVisibleState
        );
      }

      // The data type for all dataset variables is string. The string 'false' will evaluate to true.
      const requireDefaultBool = requireDefault === 'true';

      if (typeof requireOverrideCondition !== 'undefined') {
        this.applyRules(
          input,
          requireOverrideCondition,
          requireDefaultBool,
          this.toggleRequiredState
        );
      }

      const setAttributeDefaultBool = setAttributeDefault === 'true';

      if (typeof setAttributeOverrideCondition !== 'undefined') {
        this.applyRules(
          input,
          setAttributeOverrideCondition,
          setAttributeDefaultBool,
          this.toggleAttribute,
          setAttributeNameAndValue
        )
      }
    });
  }

  applyRules(input, condition, defaultBool, callback, ...params) {
    // set the input to its default state
    callback(input, defaultBool, params);

    const cleanOverrideCondition = condition.replace(/'/g, '"');
    const parsedOverrideCondition = JSON.parse(cleanOverrideCondition);

    const entries = Object.entries(parsedOverrideCondition);

    // TODO: add support for passing in a pattern as `values` in addition to an array of strings
    let toggleState = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const entry of entries) {
      const [id, values] = entry;
      if (values === 'checked' || values === '!checked') {
        const checkboxElement = document.getElementById(id);
        if (checkboxElement.type === 'checkbox') {
          if (values === 'checked' && checkboxElement.checked) {
            toggleState = true;
          } else if (values === '!checked' && !checkboxElement.checked) {
            toggleState = true;
          }
          // eslint-disable-next-line no-continue
          continue;
        }
      }

      const controlElement = document.getElementById(id);
      if (values.includes(controlElement.value)) {
        toggleState = true;
      }
    }

    if (toggleState) {
      callback(input, !defaultBool, params);
    }
  }

  /**
   * toggles the visible state of an input that was generated by ease-templating-language.
   * @param {element} field - the field to work on
   * @param {bool} shouldRequire - whether to mark the fields as required or not
   */
  toggleVisibleState(field, shouldDisplay) {
    const parentRow = document.getElementById(`${field.id}Row`);
    if (shouldDisplay) {
      parentRow.style.display = 'block';
    } else {
      parentRow.style.display = 'none';
      window.ease.page.removeError(field);
      clearInput(field);
    }
  }

  /**
   * toggles the required state of an input that was generated by ease-templating-language
   * @param {element} field - the field to work on
   * @param {bool} shouldRequire - whether to mark the fields as required or not
   */
  toggleRequiredState(field, shouldRequire) {
    // TODO: do not rely on presentation classes for logic. use either a `.js-` class or another element attribute
    const labelElement = document.querySelector(`#${field.id}Row .c-input__label`);

    if (shouldRequire) {
      field.setAttribute('data-required', 'true');
      // appending ' *' to the input's label signifies to the user that a value is required to submit the form
      if (typeof labelElement !== 'undefined' && !labelElement.innerHTML.endsWith(' *')) {
        labelElement.innerHTML += ' *';
      }
    } else {
      field.removeAttribute('data-required');
      if (typeof labelElement !== 'undefined' && labelElement.innerHTML.endsWith(' *')) {
        labelElement.innerHTML = labelElement.innerHTML.replace(' *', '');
      }
      window.ease.page.removeError(field);
    }
  }

  toggleAttribute(field, shouldSetAttribute, params) {
    const cleanOverrideCondition = params[0].replace(/'/g, '"');
    const attributeNameAndValue = JSON.parse(cleanOverrideCondition);
    for (const [key, value] of Object.entries(attributeNameAndValue)) {
      if (shouldSetAttribute) {
        field.setAttribute(key, value);
      } else {
        field.removeAttribute(key);
      }
    }
  }

  /**
   * propagate change events to other inputs who may need to recalculate their value or validation
   * @param {event}
   */
  propagateChange({
    target: {
      dataset: { propagateChangeToId }
    }
  }) {
    // TODO: add support for array of ids
    const input = document.querySelector(`#${propagateChangeToId}`);
    if (input && input.dispatchEvent) {
      input.dispatchEvent(new Event('change'));
    }
  }
}

import { Controller } from 'stimulus';
import $ from 'jquery';
import Stickyfill from 'stickyfilljs';
import { addDataAction } from '../lib/utils';

export default class extends Controller {
    static targets = ['row', 'checkbox', 'stickyHeader'];

    initialize() {
      this.selectAllId = '#' + $(this.element).data('select-all-id');
      this.selectedId = '#' + $(this.element).data('selected-id');

      this.initialized = false;
      this.update();
      this.initialized = true;

      if (this.hasStickyHeaderTarget) {
        const stickyHeader = this.stickyHeaderTarget;
        Stickyfill.add(stickyHeader);
        addDataAction(stickyHeader, 'scroll@window->list#toggleStickyHeader')
      }
    }

    toggleStickyHeader() {
        const $header = $(this.stickyHeaderTarget);
        const display = $header.offset().top === $(window).scrollTop();

        $header.toggleClass('shadow', display);
        $header.find('.c-menu').css('display', display ? 'block' : 'none');
        $header.find('.action-title').css('display', display ? 'none' : 'block');
    }

    selectAll(event) {
      const checked = $(event.target).is(':checked');

      this.checkboxes.map(checkbox =>
        $(checkbox).prop('checked', checked)
      );

      this.update();
    }

    select(event) {
      const $target = $(event.target);
      if ($target.is('a') || $target.parent().is('a')) return;

      const $row = $(event.currentTarget);
      const $cb = $row.find('input[type=\'checkbox\']').first();
      $cb.prop('checked', !$cb.is(':checked'));
      this.update();
    }

    update() {
      if (!this.checkboxes) return;

      let ids = [];
      let all = true;

      this.checkboxes.forEach(checkbox => {
        const $cb = $(checkbox);
        const $row = $cb
          .parents('div.e-row')
          .parents('div.e-container')
          .parents('div')
          .first();

        if ($cb.is(':checked')) {
          $row.addClass('selected');
          if ($cb.attr('id')) {
            // Add Id
            ids.push($cb.attr('id'));
          }
        } else {
          all = false;
          $row.removeClass('selected');
          if ($cb.attr('id')) {
            // Remove Id
            ids = ids.filter(value => $cb.attr('id') !== value);
          }
        }
      });

      $(this.selectAllId).prop('checked', all);

      if (this.initialized) {
        $(this.selectedId)
          .val(ids.join(','))
          .get(0)
          .dispatchEvent(new Event('change'));
      }
    }

    get checkboxes() {
      return this.checkboxTargets;
    }

    get rows() {
      return this.rowTargets;
    }
}

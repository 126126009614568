import { Controller } from 'stimulus';
import { addDataAction, formatAsCurrency } from '../lib/utils';

export default class extends Controller {
  static targets = ['trigger', 'addend', 'total'];

  connect() {
    if (!this.hasTriggerTarget) return;
    this.triggerTargets.forEach(target => {
      addDataAction(target, 'change->sum#compute');
    });
    this.compute();
  }

  compute() {
    if (!this.hasTotalTarget || !this.hasAddendTarget) return;

    // Global ease method
    // eslint-disable-next-line
    const reducer = (acc, { value }) => acc + Number(ease.page.sanitizeDecimal(value));
    const compute = () =>
      (this.totalTarget.value = formatAsCurrency(this.addendTargets.reduce(reducer, 0)));
    // set timeout to allow other operations that might alter the addends to finish
    setTimeout(compute, 0);
  }
}

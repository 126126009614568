export default function defineAvatarClip(svg, id, config) {

  const defs = svg.append('svg:defs');

  defs
    .append('clipPath')
    .attr('id', id)
    .append('rect')
    .attr('width', config.avatarWidth)
    .attr('height', config.nodeHeight)
    .attr('rx', config.borderRadius)
    .attr('ry', config.borderRadius);
}

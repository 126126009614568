const imageExists = url => {

  const img = new Image();

  img.onerror = function() {
    return false
  };

  img.onload = function() {
    return true;
  }

  img.src = url;
}

export default imageExists;

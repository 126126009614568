import $ from 'jquery';
import { Controller } from 'stimulus';
import 'rateyo';

export default class extends Controller {
  static targets = ['stars', 'input'];

  initialize() {
    $(this.starsTarget).rateYo({
      fullStar: true,
      onSet: rating => $(this.inputTarget).val(rating)
    });
  }
}

// tooltip_controller.js
import { Controller } from 'stimulus';
import $ from 'jquery';
import Message from '../lib/message';

export default class extends Controller {
  static targets = ['message'];

  initialize() {
    this.message = new Message(this.element, 'c-message c-message--tooltip');

    this.clickToOpen = $(this.element).attr('data-clicktoopen');
  }

  hideTooltip = event => {
    if (event && event.type === 'click') {
      event.stopPropagation();
    }
    $(this.messageTarget).hide();

    if (this.clickToOpen) {
      window.removeEventListener('click', this.hideTooltip, true);
    }
  };

  showTooltip = event => {
    // hide all tooltips
    // to prevent multiple tooltips from being visible at the same time
    $('.c-message--tooltip').hide();

    this.message.updatePosition();
    $(this.messageTarget).show();

    event.stopPropagation();

    if (this.clickToOpen) {
      window.addEventListener('click', this.hideTooltip, true);
    }
  };

  click() {
    if (!$(this.element).data('href')) return;

    window.open($(this.element).data('href'));
  }
}

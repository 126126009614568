/* eslint-disable no-param-reassign */
import { Controller } from 'stimulus';
import {
  addDataAction,
  addAttribute,
  formatAsCurrency,
  roundToTwoDigits,
  toInt
} from '../lib/utils';

/* ## How to use this controller ##
 *
 * Add the 'data-controller=input' attribute to form.
 * To format as interger or currency (in USD)
 *   Add the data-target='input.integer' or
 *   add the data-target='input.currency' attribute the input.
 *
 * To restrict what characters the user can type in an input:
 *   Add the data-target='input.restrictedInput' attribute to the input
 *   Add the data-allowed-input-regex attribute to the input. The value of this input is a Javascript regex e.g. '[0-9]' or '[a-zA-Z\s\.]'. Regex reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 *   Useful regexes:
 *     Up to three digits with optional two decimal places: '^\d{0,3}(?:\.\d{0,2})?'
 *
 * To restrict the number of characters a user can add to an input:
 *   Add the maxlength attribute to the input. The value is a number. Maxlength reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/maxlength
 *
 * To perform client side input validation:
 *    Add the data-target='input.SSN' or
 *    add the data-target='input.employeeBirthDate'
 *    add the data-target='input.zip'
 *    add the data-target='input.hireDate' attribute to the input
 *
 * Note: to perform zip code validation a countryTarget must also be available within the same input controller.
 *
 */

export default class extends Controller {
  static targets = [
    'currency',
    'int',
    'restrictedInput',
    'SSN',
    'employeeBirthDate',
    'zip',
    'country',
    'hireDate',
    'email',
    'numericalRange',
    'dateRange'
  ];

  connect() {
    if (this.hasCurrencyTarget) {
      this.currencyTargets.forEach(field => field.setAttribute('onfocusin', 'this.select()'));
      this.currencyTargets.forEach(field =>
        addDataAction(
          field,
          'focus->input#formatValueAsDecimal blur->input#formatValueAsCurrency format->input#formatValueAsCurrency'
        )
      );
    }

    if (this.hasIntTarget) {
      this.intTargets.forEach(field => field.setAttribute('onfocusin', 'this.select()'));
      this.intTargets.forEach(field =>
        addDataAction(field, 'blur->input#formatValueAsInt format->input#formatValueAsInt')
      );
    }

    if (this.hasRestrictedInputTarget) {
      this.restrictedInputTargets.forEach(field => {
        if (field.dataset.allowedInputRegex) {
          addDataAction(field, 'input->input#restrictUserInput');
        }
      });
    }

    if (this.hasSSNTarget) {
      this.SSNTargets.forEach(field => {
        addAttribute(field, 'data-target', 'input.restrictedInput');
        addDataAction(field, 'input->input#restrictUserInput');
        field.setAttribute('data-allowed-input-regex', '[0-9-]');
        addDataAction(field, 'change->input#validateSSN');
      });
    }

    if (this.hasEmployeeBirthDateTarget) {
      this.employeeBirthDateTargets.forEach(field =>
        addDataAction(field, 'change->input#validateEmployeeBirthDate')
      );
    }

    if (this.hasZipTarget && this.hasCountryTarget) {
      this.zipTargets.forEach(field => addDataAction(field, 'change->input#validateZip'));
    }

    if (this.hasHireDateTarget) {
      this.hireDateTargets.forEach(field => addDataAction(field, 'change->input#validateHireDate'));
    }

    if (this.hasEmailTarget) {
      this.emailTargets.forEach(field => addDataAction(field, 'change->input#validateEmail'));
    }

    if (this.hasNumericalRangeTarget) {
      this.numericalRangeTargets.forEach(field => {
        if (field.dataset.min || field.dataset.max) {
          addAttribute(field, 'data-target', 'input.restrictedInput');
          if (!field.dataset.allowedInputRegex) {
            field.setAttribute('data-allowed-input-regex', '[0-9.-]');
          }
          addDataAction(field, 'input->input#restrictUserInput');
          addDataAction(field, 'change->input#validateNumericalRange');
        }
      });
    }

    if (this.hasDateRangeTarget) {
      this.dateRangeTargets.forEach(field => {
        if (field.dataset.min || field.dataset.max) {
          addDataAction(field, 'change->input#validateDateRange');
        }
      });
    }
  }

  formatValueAsInt({ target }) {
    target.value = toInt(target.value);
  }

  formatValueAsDecimal({ target }) {
    target.value = roundToTwoDigits(target.value);
  }

  formatValueAsCurrency({ target }) {
    target.value = formatAsCurrency(target.value);
  }

  restrictUserInput(event) {
    const { allowedInputRegex } = event.target.dataset;
    if (!allowedInputRegex) return;

    const re = new RegExp(allowedInputRegex, 'g');
    const matchingString = event.target.value.match(re) || '';
    event.target.value = matchingString ? matchingString.join('') : '';
  }

  validateSSN({ target: { value, id } }) {
    const { removeError, showError, showFeedback } = window.ease.page;

    removeError(`#${id}`);
    if (!value) return;

    // These rules are the same as the ones in Strings.cs
    const cleanValue = value.replace(/-/g, '');
    const isLengthValid = cleanValue.length === 9;
    let isInValidRange =
      cleanValue.substr(0, 3) !== '000' &&
      cleanValue.substr(0, 3) !== '666' &&
      cleanValue.substr(3, 2) !== '00' &&
      cleanValue.substr(5, 4) !== '0000';

    // if the SSN starts with a 9 it may be an ITIN (Individual Taxpayer Id Number).  ITINs don't explicity prohibit groups of zeros
    if (!isInValidRange && cleanValue.substr(0, 1) === '9') {
      isInValidRange = true;
    }

    if (!isLengthValid) {
      showError(`#${id}`);
      showFeedback('SSN must be 9 digits', 'error');
    }

    if (!isInValidRange) {
      showError(`#${id}`);
      showFeedback('Invalid SSN', 'error');
    }
  }

  validateEmployeeBirthDate({ target: { value, id } }) {
    const { removeError, showError, showFeedback } = window.ease.page;

    removeError(`#${id}`);
    if (!value) return;

    if (!window.ease.page.isValidDate(value)) {
      showError(`#${id}`);
      showFeedback('Invalid Birth Date, Expected: MM/DD/YYYY', 'error');
      return;
    }

    const dateString = ease.page.cleanDateString(value);

    const birthDate = new Date(dateString);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    if (today.getMonth() < birthDate.getMonth()) {
      --age;
    } else if (today.getMonth() === birthDate.getMonth() && today.getDay() < birthDate.getDay()) {
      --age;
    }
    // These rules are the same as the ones in Date.cs
    const tooOld = age >= 115;
    const tooYoung = age <= 13;

    if (tooOld) {
      showError(`#${id}`);
      showFeedback('Employee’s age cannot exceed 114', 'error');
    } else if (tooYoung) {
      showError(`#${id}`);
      showFeedback('Employee’s age must be at least 14', 'error');
    }
  }

  validateHireDate({ target: { value, id } }) {
    const { removeError, showError, showFeedback } = window.ease.page;

    removeError(`#${id}`);
    if (!value) return;

    if (!window.ease.page.isValidDate(value)) {
      showError(`#${id}`);
      showFeedback('Invalid Hire Date, Expected: MM/DD/YYYY', 'error');
      return;
    }

    const dateString = ease.page.cleanDateString(value);

    const hireDate = new Date(dateString);
    const today = new Date();

    let tenure = today.getFullYear() - hireDate.getFullYear();
    if (today.getMonth() < hireDate.getMonth()) {
      --tenure;
    } else if (today.getMonth() === hireDate.getMonth() && today.getDay() < hireDate.getDay()) {
      --tenure;
    }

    // These rules are the same as the ones in Date.cs
    const tooLongAgo = tenure >= 75;
    const tooFarInFuture = tenure < -1;

    if (tooLongAgo) {
      showError(`#${id}`);
      showFeedback('Hire date cannot be >74 years ago', 'error');
    } else if (tooFarInFuture) {
      showError(`#${id}`);
      showFeedback('Hire date cannot be >1 year from now', 'error');
    }
  }

  validateZip({ target: { value, id } }) {
    const { removeError, showError, showFeedback } = window.ease.page;

    removeError(`#${id}`);
    if (!value) return;
    if (!this.hasCountryTarget) return;
    let isZipValid = true;
    if (this.countryTarget.value === 'United States') {
      // Similar to regex used in Strings.cs
      const re = new RegExp(/^\d{5}$|^\d{5}[(:-\s]\d{4}\)?$/, 'g');
      isZipValid = value.match(re);
    }

    if (!isZipValid) {
      showError(`#${id}`);
      showFeedback('Invalid Zip Code', 'error');
    }
  }

  validateEmail({ target: { value, id } }) {
    const { removeError, showError, showFeedback } = window.ease.page;

    removeError(`#${id}`);
    if (!value) return;

    // Not the same regex used in Mail.cs. I was unable to translate that into a valid JS regex. This is an approximation
    const re = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+(\.[a-z]{2,})+$/, 'gi');
    const isEmailValid = value.match(re);

    if (!isEmailValid) {
      showError(`#${id}`);
      showFeedback('Invalid Email', 'error');
    }
  }

  validateNumericalRange({ target: { value, id, dataset } }) {
    const { removeError, showError, showFeedback } = window.ease.page;

    removeError(`#${id}`);
    if (!value) return;

    const { min, max } = dataset;

    if (Number.isNaN(parseInt(value, 10))) {
      showError(`#${id}`);
      showFeedback('Invalid input', 'error');
      return;
    }

    // if min or max are undefined then the result of the comparison will be `false`
    const tooLow = parseInt(value, 10) < min;
    const tooHigh = parseInt(value, 10) > max;

    if (tooLow || tooHigh) {
      showError(`#${id}`);
      showFeedback('Number not in valid range', 'error');
    }
  }

  validateDateRange({ target: { value, id, dataset } }) {
    const { removeError, showError, showFeedback } = window.ease.page;

    removeError(`#${id}`);
    if (!value) return;

    const { min, max, errorMessage } = dataset;

    // if min or max are undefined then the result of the comparison will be `false`
    const tooLow = Date.parse(value) < Date.parse(min);
    const tooHigh = Date.parse(value) > Date.parse(max);

    if (tooLow || tooHigh) {
      showError(`#${id}`);
      showFeedback(errorMessage ? errorMessage : 'Date not in valid range', 'error');
    }
  }
}

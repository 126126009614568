import { Controller } from 'stimulus';
import $ from 'jquery';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';

export default class extends Controller {
  initialize() {
    const elemId = $(this.element).attr('id');
    const jsonElement = $(this.element)[0];
    const hiddenArea = $(`#hdn${elemId}`)[0]; // Hidden textarea used for the raw, unformatted string. Used for focus and select when navigator.clipboard is not available

    if (jsonElement.localName === 'code' && jsonElement.textContent && jsonElement.textContent.length >= 2) {
      try {
        const prettified = JSON.stringify(JSON.parse(jsonElement.textContent), null, 2);

        hiddenArea.textContent = prettified;
        jsonElement.textContent = prettified;

        Prism.highlightElement(jsonElement);
      } catch {
        hiddenArea.textContent = jsonElement.textContent;
      }
    }
  }

  copy() {
    const { showFeedback } = window.ease.page;
    const copyLink = $(this.element);
    const copyLinkId = copyLink.attr('id');
    const hiddenAreaId = copyLinkId.replace('copy', 'hdn');
    const hiddenArea = $(`#${hiddenAreaId}`)[0];

    // These copy methods only work over https or if your local browser settings allow copy to clipboard.
    // If you're trying to debug locally (on Chrome) and don't have a self-signed cert to mimic https,
    // check Settings > Privacy & Security > Site Settings > View Permissions > ease.ease-app:8888 > Clipboard.
    try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(hiddenArea.textContent);
      } else {
        hiddenArea.focus();
        hiddenArea.select();

        document.execCommand('Copy');
      }

      copyLink.textContent = 'Copied!';
      setTimeout(function() {
        copyLink.textContent = 'Copy to Clipboard';
      }, 2250);

      showFeedback('Copied to the clipboard.');
    } catch {
      showFeedback('Could not copy the value.', 'error');
    }
  }
}

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

// Stimulus Polyfills for ie11
import 'core-js/fn/object/assign';
import 'core-js/fn/set';
import 'core-js/fn/map';
import 'core-js/fn/promise';
import 'core-js/fn/array/from';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'element-closest';
import 'mutation-observer-inner-html-shim';

const stimulus = () => {
  const application = Application.start();
  const context = require.context('./controllers', true, /\.js$/);
  
  application.load(definitionsFromContext(context));
}

export default stimulus;
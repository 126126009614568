import $ from 'jquery';

export default class Message {
    constructor(target, className) {
        this.target = target;
        this.className = className;
        this.initialize();
    }

    initialize() {
        $.expr.filters.offscreenright = el => {
            const rect = el.getBoundingClientRect();
            const totalWidth = (rect.x || rect.left) + rect.width;
            return totalWidth > Math.min(window.innerWidth, window.screen.availWidth);
        };

        $.expr.filters.offscreenleft = el => {
            const rect = el.getBoundingClientRect();
            return (rect.x || rect.left) < 0;
        };

        $.expr.filters.offscreentop = el => {
            const rect = el.getBoundingClientRect();
            return (rect.y || rect.top) < 0;
        };

        const $target = $(this.target);
        const info = $target.attr('data-info');
        const infoHtml = $target.attr('data-info-html');

        if (info) {
            // message is constructed using data-info attribute and appended to the target
            this.element = $('<div />', {
                class: this.className,
                text: info
            }).appendTo($target);
        } else if (infoHtml) {
            // Links are not appended to messages that contain html.  If links are desired add them to the html content.
            // message is constructed using data-html attribute and appended to the target
            this.element = $('<div />', {
                class: this.className,
            }).append(infoHtml).appendTo($target);
        } else {
            // else this is a custom message that is a child of the target
            const $message = $target.find(
                this.className
                    .split(' ')
                    .map(element => `.${element}`)
                    .join('')
            );
            this.element = $message;
        }

        if (info || infoHtml) {
            const label = $target.attr('data-label');
            const url = $target.attr('data-href');

            if (label && url) {
                const $link = $('<a />', {
                    class: 'c-message__link',
                    href: url,
                    target: '_blank',
                    text: label
                });
                this.element.append($link);
            }
        }
        
        this.element.attr('data-target', 'tooltip.message');

        // prevent bubbling/default to input mousedown/focus events
        this.element.on('mousedown', event => {
            event.stopPropagation();
            event.preventDefault();
        });
    }

    resetPosition($element) {
        $element.removeAttr('style');
        $element.removeClass('c-message--right c-message--left c-message--top c-message--bottom');
    }

    updatePosition = () => {
        const $element = $(this.element);
        const $target = $(this.target);

        const containerWidth = $target.outerWidth();
        const containerHeight = $target.outerHeight();
        const messageWidth = $element.outerWidth();
        const messageHeight = $element.outerHeight();

        // Try position right
        this.resetPosition($element);
        $element.css('right', `${-(parseInt(messageWidth, 10) + 10)}px`);
        $element.css('top', `${containerHeight / 2.0 - messageHeight / 2.0}px`);

        if (!$element.is(':offscreenright')) {
            $element.addClass('c-message--right');
            return;
        }

        // Try position left
        this.resetPosition($element);
        $element.css('left', `${-(parseInt(messageWidth, 10) + 10)}px`);
        $element.css('top', `${containerHeight / 2.0 - messageHeight / 2.0}px`);

        if (!$element.is(':offscreenleft')) {
            $element.addClass('c-message--left');
            return;
        }

        // Try position top
        this.resetPosition($element);
        $element.css('left', `${containerWidth / 2.0 - messageWidth / 2.0}px`);
        $element.css('top', `${-(parseInt(messageHeight, 10) + 10)}px`);

        if (!$element.is(':offscreentop')) {
            $element.addClass('c-message--top');
            return;
        }

        // If all else fails, position bottom
        this.resetPosition($element);
        $element.css('left', `${containerWidth / 2.0 - messageWidth / 2.0}px`);
        $element.css('bottom', `${-(parseInt(messageHeight, 10) + 10)}px`);
        $element.addClass('c-message--bottom');
    };

    close() {
        $(this.element).hide();
    }
}

import { Controller } from 'stimulus';
import $ from 'jquery';
import { addDataAction } from '../lib/utils';

export default class extends Controller {
  initialize() {
    // this handler will ensure that the jquery datepicker closes properly
    // which sometimes does not happen when the input is inside a modal
    // if we can figure out why the modal interferes with closing
    // the datepicker this will become redundant
    addDataAction(this.element, 'focus->date#handleFocus')
  }

  resetDynamicDatepicker = () => {
      var target = $(this.element);
      var date = target.datepicker("getDate");
      target.removeClass('hasDatepicker');
      target.datepicker(ease.datepicker.settings);
      target.datepicker("setDate", date);
      target.datepicker('show');

 };
 
  handleFocus = () => {
    if ($(this.element).hasClass('hasDatepicker')) {
      document.addEventListener('click', this.handleClickOutsideDatepicker, true);
    }
  };

  handleClickOutsideDatepicker = e => {
    const clickInInput = this.element.contains(e.target);
    const clickInDatepicker = $('#ui-datepicker-div')[0].contains(e.target);
    const clickedOutside = !clickInInput && !clickInDatepicker;
    if (clickedOutside) {
      $(this.element).datepicker('hide');
      document.removeEventListener('click', this.handleClickOutsideDatepicker, true);
    }
  };
}

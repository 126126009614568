import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  initialize() {
    window.addEventListener('resize', this.handleResize);
  }

  handleResize() {
    if (window.innerWidth > 575 && $('.o-wrapper__content').not(':visible')) {
      $('.o-wrapper__content').show();
    } else if (window.innerWidth <= 575 && $('.o-wrapper__sidebar').hasClass('is-open')) {
      $('.o-wrapper__content').hide();
    }
  }

  handleClick() {
    const sideNav = $('.c-nav-side');
    const mobileIndicator = $('.u-mobile-indicator');
    const tabletIndicator = $('.u-tablet-indicatior');
    const wrapperSidebar = $('.o-wrapper__sidebar');
    const wrapper = $('.o-wrapper');

    if (
      tabletIndicator.is(':visible') &&
            sideNav.hasClass('is-active') &&
            wrapperSidebar.hasClass('is-collapsed')
    ) {
      return sideNav.removeClass('is-active');
    }

    mobileIndicator.is(':visible')
      ? sideNav.toggleClass('is-active')
      : sideNav.removeClass('is-active');

    sideNav.toggleClass('is-collapsed');
    wrapperSidebar.toggleClass('is-collapsed');
    wrapper.toggleClass('is-collapsed');

    return undefined;
  }

  openMenu() {
    $('.o-wrapper__content').hide();
    $('.o-wrapper__sidebar').addClass('is-open');
  }

  closeMenu() {
    $('.o-wrapper__content').show();
    $('.o-wrapper__sidebar').removeClass('is-open');
  }
}

/* eslint-disable no-param-reassign */
import wrapText from './utils/wrap-text';
import getCursorForNode from './utils/getCursorForNode';
import imageExists from './utils/imageExists';
import renderLines from './render-lines';
import onClick from './on-click';
import iconPeople from './components/icon-people';
import isIE from './utils/isIE';

const CHART_NODE_CLASS = 'org-chart-node';
const PERSON_TITLE_CLASS = 'org-chart-person-title';

export default function render(config) {
  const {
    svg,
    tree,
    animationDuration,
    nodeWidth,
    nodeHeight,
    nodeBorderRadius,
    backgroundColor,
    initialsContainerHeight,
    fontColorDark,
    borderColor,
    avatarWidth,
    avatarBackgroundColorLight,
    avatarBackgroundColorDark,
    lineDepthY,
    treeData,
    sourceNode,
    avatarSize,
    reportsBadgeWidth,
    reportsBadgeHeight,
    reportsBadgeVerticalDistance,
    peopleIconHeight,
    peopleIconWidth
  } = config;

  // Compute the new tree layout.
  const nodes = tree.nodes(treeData).reverse();
  const links = tree.links(nodes);

  config.links = links;
  config.nodes = nodes;
  // Normalize for fixed-depth.
  nodes.forEach(function(d) {
    d.y = d.depth * lineDepthY;
  });

  // Update the nodes
  const node = svg.selectAll(`g.${CHART_NODE_CLASS}`).data(nodes.filter(d => d.id), d => d.id);

  const parentNode = sourceNode || treeData;

  // Enter any new nodes at the parent's previous position.
  const nodeEnter = node
    .enter()
    .insert('g')
    .attr('class', CHART_NODE_CLASS)
    .attr('transform', `translate(${parentNode.x0}, ${parentNode.y0})`)
    .on('click', onClick(config));

  // Person Card Shadow
  nodeEnter
    .append('rect')
    .attr('width', nodeWidth)
    .attr('height', nodeHeight)
    .attr('fill', backgroundColor)
    .attr('stroke', borderColor)
    .attr('rx', nodeBorderRadius)
    .attr('ry', nodeBorderRadius)
    .attr('fill-opacity', 0.05)
    .attr('stroke-opacity', 0.025)
    .attr('filter', 'url(#boxShadow)');

  // Person Card Container
  nodeEnter
    .append('rect')
    .attr('width', nodeWidth)
    .attr('height', nodeHeight)
    .attr('id', d => d.id)
    .attr('fill', backgroundColor)
    .attr('stroke', borderColor)
    .attr('rx', nodeBorderRadius)
    .attr('ry', nodeBorderRadius)
    .style('cursor', getCursorForNode)
    .attr('class', 'box');

  // user container
  nodeEnter
    .filter(d => d.id !== 'default')
    .append('rect')
    .attr('fill', avatarBackgroundColorLight)
    .attr('width', avatarWidth)
    .attr('height', nodeHeight)
    .attr('x', 0)
    .attr('y', 0)
    .style('cursor', getCursorForNode);

  // initials container
  nodeEnter
    .filter(d => d.id !== 'default')
    .append('rect')
    .attr('height', initialsContainerHeight)
    .attr('width', initialsContainerHeight)
    .attr('x', avatarWidth / 2 - initialsContainerHeight / 2)
    .attr('y', nodeHeight / 2 - initialsContainerHeight / 2)
    .attr('fill', avatarBackgroundColorDark)
    .attr('rx', initialsContainerHeight / 2)
    .attr('ry', initialsContainerHeight / 2)
    .style('cursor', getCursorForNode);

  // initials
  nodeEnter
    .filter(d => d.id !== 'default')
    .append('text')
    .text(d => d.initials)
    .attr('text-anchor', 'middle')
    .attr('x', avatarWidth / 2)
    .attr('y', () => {
      if (isIE()) {
        return nodeHeight / 2 + 7;
      }
      return nodeHeight / 2;
    })
    .attr('dominant-baseline', 'central')
    .style('font-weight', 600)
    .style('font-size', '22px')
    .style('fill', fontColorDark)
    .style('cursor', getCursorForNode);

  // avatar
  nodeEnter
    .filter(d => {
      if (d.id !== 'default') {
        return true;
      }

      if (imageExists(d.avatar)) {
        return true;
      }

      return false;
    })
    .append('image')
    .attr('width', avatarSize)
    .attr('height', avatarSize)
    .attr('x', -(avatarSize - avatarWidth) / 2.0 - 8)
    .attr('y', -(avatarSize - nodeHeight) / 2.0)
    .attr('stroke', borderColor)
    .attr('src', d => d.avatar)
    .attr('xlink:href', d => d.avatar)
    .attr('clip-path', 'url(#avatarClip)')
    .style('cursor', getCursorForNode);

  // avatar clip
  nodeEnter
    .filter(d => d.id !== 'default')
    .append('rect')
    .attr('width', 10)
    .attr('height', nodeHeight)
    .attr('fill', backgroundColor)
    .attr('x', avatarWidth - 8)
    .attr('y', 0)
    .style('cursor', getCursorForNode);

  // name
  nodeEnter
    .append('text')
    .attr('class', `${PERSON_TITLE_CLASS} unedited`)
    .attr('x', d => (d.id === 'default' ? nodeWidth / 2 : avatarWidth + 4))
    .attr('y', d => {
      if (d.id === 'default') {
        return nodeHeight / 2 - 9;
      }

      return 16;
    })
    .attr('dominant-baseline', d => (d.id === 'default' ? 'middle' : 'auto'))
    .attr('dy', '.3em')
    .style('fill', fontColorDark)
    .style('font-size', '16px')
    .style('font-weight', '800')
    .style('cursor', getCursorForNode)
    .style('text-anchor', d => (d.id === 'default' ? 'middle' : 'start'))
    .text(d => {
      if (d.lastName && d.lastName.length > 18) {
        return `${d.firstName} ${d.lastName.substring(0, 14)}...`;
      }
      return d.fullName;
    });

  // job title
  nodeEnter
    .filter(d => d.id !== 'default')
    .append('text')
    .attr('x', avatarWidth + 4)
    .attr('y', d => (d.fullName && d.fullName.length > 18 ? 52 : 34))
    .attr('dy', '.3em')
    .style('fill', fontColorDark)
    .style('font-size', '12px')
    .style('font-weight', '400')
    .style('cursor', getCursorForNode)
    .text(d => {
      if (d.jobTitle && d.jobTitle.length > 26) {
        return `${d.jobTitle.substring(0, 23)}...`;
      }
      return d.jobTitle;
    });

  // separator
  nodeEnter
    .filter(d => d.id !== 'default')
    .append('rect')
    .attr('width', nodeWidth - avatarWidth - 4)
    .attr('height', 1)
    .attr('x', avatarWidth + 4)
    .attr('y', d => (d.fullName && d.fullName.length > 18 ? 64 : 46))
    .style('fill', fontColorDark)
    .style('cursor', getCursorForNode);

  // department
  nodeEnter
    .filter(d => d.id !== 'default')
    .append('text')
    .attr('x', avatarWidth + 4)
    .attr('y', d => (d.fullName && d.fullName.length > 18 ? 81 : 63))
    .attr('dy', '.3em')
    .style('fill', '#8A92A0')
    .style('font-size', '12px')
    .style('font-weight', '400')
    .text('Department')
    .style('cursor', getCursorForNode);

  nodeEnter
    .filter(d => d.id !== 'default')
    .append('text')
    .attr('x', avatarWidth + 4)
    .attr('y', d => (d.fullName && d.fullName.length > 18 ? 98 : 80))
    .attr('dy', '.3em')
    .style('fill', fontColorDark)
    .style('font-size', '12px')
    .style('font-weight', '400')
    .style('cursor', getCursorForNode)
    .text(d => d.department);

  // direct reports rect
  nodeEnter
    .filter(d => d.totalReports > 0)
    .append('rect')
    .attr('width', reportsBadgeWidth)
    .attr('height', reportsBadgeHeight)
    .attr('x', nodeWidth - reportsBadgeWidth)
    .attr('y', nodeHeight - reportsBadgeHeight - reportsBadgeVerticalDistance)
    .style('fill', fontColorDark)
    .style('cursor', getCursorForNode);

  // direct reports circle
  nodeEnter
    .filter(d => d.totalReports > 0)
    .append('circle')
    .attr('r', reportsBadgeHeight / 2)
    .attr('cx', nodeWidth - reportsBadgeWidth)
    .attr('cy', nodeHeight - reportsBadgeHeight / 2 - reportsBadgeVerticalDistance)
    .style('fill', fontColorDark)
    .style('cursor', getCursorForNode);

  // direct reports text
  const iconPeopleMargin = 4;
  nodeEnter
    .filter(d => d.totalReports > 0)
    .append('text')
    .attr('x', nodeWidth - reportsBadgeWidth + peopleIconWidth + iconPeopleMargin)
    .attr('y', nodeHeight - reportsBadgeHeight / 2 - reportsBadgeVerticalDistance)
    .attr('dy', '.3em')
    .style('cursor', 'pointer')
    .style('fill', 'white')
    .style('font-size', '12px')
    .style('font-weight', '400')
    .text(d => d.totalReports)
    .style('cursor', getCursorForNode);

  // people icon
  iconPeople({
    svg: nodeEnter.filter(d => d.totalReports > 0),
    height: peopleIconHeight,
    width: peopleIconWidth,
    x: nodeWidth - reportsBadgeWidth,
    y: nodeHeight - reportsBadgeHeight / 2 - reportsBadgeVerticalDistance - peopleIconHeight / 2
  });

  // Transition nodes to their new position.
  const nodeUpdate = node
    .transition()
    .duration(animationDuration)
    .attr('transform', d => `translate(${d.x},${d.y})`);

  nodeUpdate
    .select('rect.box')
    .attr('fill', backgroundColor)
    .attr('stroke', borderColor);

  // Transition exiting nodes to the parent's new position.
  node.exit()
    .transition()
    .duration(animationDuration)
    .attr('transform', () => `translate(${parentNode.x},${parentNode.y})`)
    .remove();

  // Wrap the title texts
  svg.selectAll(`text.unedited.${PERSON_TITLE_CLASS}`).call(wrapText, 140);

  // Render lines connecting nodes
  renderLines(config);

  // Stash the old positions for transition.
  nodes.forEach(function(d) {
    d.x0 = d.x;
    d.y0 = d.y;
  });
}

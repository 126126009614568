// input_controller.js
import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['sidebar', 'content'];

    goToMenu() {
      $(this.sidebarTarget).addClass('is-visible');
      $('.o-content').css('overflow', 'hidden');
    }
}

import { Controller } from 'stimulus';
import $ from 'jquery';

const POLL_FREQUENCY_MS = 5000;

const ACTION_REFRESH_ALL_FORM = 'refreshCompanyForm';
const ACTION_REFRESH_ONE_FIELD = 'refreshReadinessStatusCompanyId';
const ACTION_MIGRATE_COMPANY_FIELD = 'migrationCompanyId';
const ACTION_DISPLAY_DEACTIVATE_COMPANY_FIELD = "displayDeactivateCompanyModalId";
const ACTION_CONFIRM_DEACTIVATE_COMPANY_FORM = 'deactivateCompanyForm';
const ACTION_DISPLAY_MARK_MIGRATED_FIELD = 'markCompanyAsMigratedCompanyId';
const ACTION_DISPLAY_MARK_MIGRATED_MODAL = 'modalAlreadyMigratedCompany';
const ACTION_CONFIRM_MARK_MIGRATED_FORM = 'markCompanyAsMigratedCompanyForm';
 
const COMPANY_STATUS_MIGRATING = 'Migrating';
const COMPANY_STATUS_MIGRATED = 'Migrated';
const COMPANY_STATUS_INACTIVE = 'Inactive';

const READINESS_STATUS_QUEUED = 'Queued';
const READINESS_STATUS_STALE = 'Stale';
const READINESS_STATUS_PENDING = 'Pending';
const READINESS_STATUS_NOT_READY = 'NotReady';
const READINESS_STATUS_READY = 'Ready';

const REPORT_STATUS_GENERATING = 'Generating';
const REPORT_STATUS_COMPLETE = 'Complete';
const REPORT_STATUS_FAILED = 'Failed';

const REFRESH_PENDING_TEXT = 'Refreshing';

const TEMPLATE_READINESS_IS_READY = 'readinessIsReadyTemplate';
const TEMPLATE_READINESS_WARNING = 'readinessWarningsTemplate';
const TEMPLATE_READINESS_ERRORS = 'readinessErrorsTemplate';

const TEMPLATE_NEXT_STEP_TEXT = 'nextStepTextTemplate';
const TEMPLATE_NEXT_STEP_PROGRESS = 'nextStepProgressTemplate';
const TEMPLATE_NEXT_STEP_REFRESH = 'nextStepRefreshTemplate';
const TEMPLATE_NEXT_STEP_MIGRATE = 'nextStepMigrateTemplate';
const TEMPLATE_NEXT_STEP_REPORT = 'nextStepReportTemplate';
const TEMPLATE_NEXT_STEP_VIEW_IN_EN = 'nextStepViewInENTemplate';

const TEMPLATE_POST_MIGRATION_REPORT_GENERATING = 'postMigrationReportGeneratingTemplate';
const TEMPLATE_POST_MIGRATION_REPORT_FAILED = 'postMigrationReportFailedTemplate';
const TEMPLATE_POST_MIGRATION_REPORT_BUTTON = 'postMigrationReportButtonTemplate';
const TEMPLATE_RESULT_MIGRATION_FAILED_BUTTON = 'resultMigrationFailedTemplate';

const TEMPLATE_DEACTIVATE_BUTTON = 'deactivateButtonTemplate';
const TEMPLATE_DEACTIVATE_AND_ALREADY_MIGRATED_LINK = 'deactivateAndAlreadyMigratedLinkTemplate';
const TEMPLATE_PROGRESS_LOGO = 'progressLogoTemplate';

const CONTAINER_STATUS = '.company-status';
const CONTAINER_NEXT_STEP = '.next-step-container';
const CONTAINER_READINESS = '.readiness-container';
const CONTAINER_POST_MIGRATION_REPORT = '.post-migration-container';
const CONTAINER_OTHER = '.other-details-container';

export default class extends Controller {
  initialize() {
    this.xsrf = $('input[type="hidden"][name="_xsrf_"]').val(); // Get the XSRF from any form on the page

    this.companyIds = this.getAllCompanyIds().join(', '); // This is the format for list expected by the ease backend

    const initialEventsJson = $('#dashboard-initial-events').remove().val();
    if (initialEventsJson) {
      const initialEvents = JSON.parse(initialEventsJson);
      initialEvents.forEach(x => this.updateCompanyRow(x));
    }
  }

  connect() {
    this.cancelPendingPoll();
    this.interval = setInterval(() => this.poll(), POLL_FREQUENCY_MS);
  }

  disconnect() {
    this.cancelPendingPoll();
    clearInterval(this.interval);
  }

  cancelPendingPoll() {
    this.polling?.abort();
    this.polling = null;
  }

  async poll() {
    if (this.polling) return; // Prevent overlapping executions
    this.polling = new AbortController();
    const { signal } = this.polling;

    try {
      const formData = new FormData();
      formData.append('_formid_', ACTION_REFRESH_ALL_FORM);
      formData.append('_xsrf_', this.xsrf);
      formData.append('companyIds', this.companyIds);

      const response = await fetch(window.location.href, {
        method: 'POST',
        body: formData,
        signal,
      });

      if (response.ok) {
        const data = await response.json();
        const { details } = data.jsonData;
        this.updateAllCompanyRows(details);
      } else {
        console.error('Polling failed:', response.statusText);
      }
    } catch (error) {
      // When polling is cancelled/aborted, it'll arrive here as an error.
      // Though, it's one we're expecting, so we can drop the severity.
      if (signal.aborted) {
        console.log('Polling cancelled');
      } else {
        console.error('Polling error:', error);
      }
    } finally {
      this.polling = null;
    }
  }

  getAllCompanyIds() {
    return $(this.element)
      .find('[data-row-company-id]')
      .get()
      .map(el => $(el).data('rowCompanyId'));
  }

  // data-action="click->..."
  beginRefreshCompany(clickEvent) {
    clickEvent.preventDefault();

    let companyRow = $(clickEvent.target)
      .closest('[data-row-company-id]');

    let companyId = companyRow.data('rowCompanyId');
    let refreshSaveField = companyRow.data('refreshSaveField');

    ease.page.saveFieldValue(ACTION_REFRESH_ONE_FIELD, companyId);

    // Show as 'Refreshing' in UI immediately to give user better feedback
    let template = document.getElementById(TEMPLATE_NEXT_STEP_TEXT).innerHTML
      .replace('{{nextStepText}}', REFRESH_PENDING_TEXT);

    companyRow.find(CONTAINER_READINESS).empty();
    companyRow.find(CONTAINER_NEXT_STEP).html(template);

    // Prevent an active poll populating the company with now-stale information.
    this.cancelPendingPoll();
  }

  // data-action="click->..."
  beginMigrateCompany(clickEvent) {
    clickEvent.preventDefault();

    let companyRow = $(clickEvent.target)
      .closest('[data-row-company-id]');

    let companyId = companyRow.data('rowCompanyId');

    ease.page.saveFieldSubmit(ACTION_MIGRATE_COMPANY_FIELD, companyId);
  }

  // data-action="click->..."
  beginDeactivateCompany(clickEvent) {
    clickEvent.preventDefault();

    let companyRow = $(clickEvent.target)
      .closest('[data-row-company-id]');

    let companyId = companyRow.data('rowCompanyId');

    ease.page.saveFieldValue(ACTION_DISPLAY_DEACTIVATE_COMPANY_FIELD, companyId, null);
  }
  
  // data-action="click->..."
  // from modal instead of table row
  confirmDeactivateCompany(clickEvent) {
    clickEvent.preventDefault();

    ease.page.submit(`#${ACTION_CONFIRM_DEACTIVATE_COMPANY_FORM}`);
  }

  // data-action="click->..."
  beginMarkAlreadyMigrated(clickEvent) {
    clickEvent.preventDefault();

    let companyRow = $(clickEvent.target)
      .closest('[data-row-company-id]');

    let companyId = companyRow.data('rowCompanyId');

    ease.page.val(`#${ACTION_DISPLAY_MARK_MIGRATED_FIELD}`, companyId);
    ease.Modals.openModal(ACTION_DISPLAY_MARK_MIGRATED_MODAL);
  }

  // data-action="click->..."
  // from modal instead of table row
  confirmMarkAlreadyMigrated(clickEvent) {
    clickEvent.preventDefault();

    ease.page.submit(`#${ACTION_CONFIRM_MARK_MIGRATED_FORM}`);
  }

  updateAllCompanyRows(details) {
    if (details !== undefined && details.length > 0) {
      details.forEach(x => this.updateCompanyRow(x));
    }
  }

  updateCompanyRow(details) {
    const companyRow = $(`[data-row-company-id="${details.CompanyId}"]`);
    if (companyRow.length === 0) {
      // Event is for a company not displayed
      return;
    }

    const status = companyRow.find(CONTAINER_STATUS);
    status.text(details.CompanyStatus);

    switch (details.CompanyStatus) {
      case COMPANY_STATUS_MIGRATING:
        this.updateForMigrating(companyRow, details);
        break;
      case COMPANY_STATUS_MIGRATED:
        this.updateForMigrated(companyRow, details);
        break;
      case COMPANY_STATUS_INACTIVE:
        this.updateForInactive(companyRow, details);
        break;
      default:
        this.updateForActive(companyRow, details);
    }
  }

  updateForInactive(companyRow, details) {
    const nextStepContainer = companyRow.find(CONTAINER_NEXT_STEP);
    const readinessContainer = companyRow.find(CONTAINER_READINESS);
    const postReportContainer = companyRow.find(CONTAINER_POST_MIGRATION_REPORT);
    const otherContainer = companyRow.find(CONTAINER_OTHER);

    // Result col
    this.updatePostReport(details, postReportContainer);

    nextStepContainer.empty();
    readinessContainer.empty();
    otherContainer.empty();
  }

  updateForMigrating(companyRow, details) {
    const nextStepContainer = companyRow.find(CONTAINER_NEXT_STEP);
    const readinessContainer = companyRow.find(CONTAINER_READINESS);
    const postReportContainer = companyRow.find(CONTAINER_POST_MIGRATION_REPORT);
    const otherContainer = companyRow.find(CONTAINER_OTHER);

    // Readiness col
    readinessContainer.empty();

    // Other col
    otherContainer.empty();

    // Next step col (show progress status text)
    const text = details.MigrationProgressText ?? '';
    let nextStepTemplate = document.getElementById(TEMPLATE_NEXT_STEP_PROGRESS).innerHTML;
    nextStepTemplate = nextStepTemplate.replace('{{migrationStep}}', text);
    nextStepContainer.html(nextStepTemplate);

    // Result col
    if (postReportContainer.find('.logo-container').length === 0) {
      // Add the logo container if not already added
      // Otherwise do not add to allow animation of progress change
      const progressTemplate = document.getElementById(TEMPLATE_PROGRESS_LOGO).innerHTML;
      postReportContainer.html(progressTemplate);
    }

    // Update the progress

    const progress = Math.min(100, Math.max(0, details.MigrationProgressPercent));

    const logoFillOuter = postReportContainer.find('.logo-fill-outer');
    const logoFillInner = postReportContainer.find('.logo-fill-inner');

    // Fill outer circle first (0-80%), then inner star (80-100%)
    if (progress <= 80) {
      const outerProgress = (progress / 80) * 100;
      logoFillOuter.css('--progress', `${outerProgress}%`);
      logoFillInner.css('clip-path', 'inset(100% 0 0 0)');
    } else {
      logoFillOuter.css('--progress', '100%');
      const innerClipValue = 100 - ((progress - 80) / 20) * 100;
      logoFillInner.css('clip-path', `inset(${innerClipValue}% 0 0 0)`);
    }
  }

  updateForMigrated(companyRow, details) {
    const nextStepContainer = companyRow.find(CONTAINER_NEXT_STEP);
    const readinessContainer = companyRow.find(CONTAINER_READINESS);
    const postReportContainer = companyRow.find(CONTAINER_POST_MIGRATION_REPORT);
    const otherContainer = companyRow.find(CONTAINER_OTHER);

    // Readiness col
    readinessContainer.empty();

    // Next step col
    if (details.MigrationSuccess) {
      let template = document.getElementById(TEMPLATE_NEXT_STEP_VIEW_IN_EN).innerHTML;
      template = template.replace('{{enCompanyUrl}}', details.ViewInEnUrl);
      nextStepContainer.html(template);
    } else {
      nextStepContainer.empty();
    }

    // Result col
    this.updatePostReport(details, postReportContainer);

    // Other col
    let deactivateTemplate = document.getElementById(TEMPLATE_DEACTIVATE_BUTTON).innerHTML;
    otherContainer.html(deactivateTemplate);
  }

  updateForActive(companyRow, details) {
    const nextStepContainer = companyRow.find(CONTAINER_NEXT_STEP);
    const readinessContainer = companyRow.find(CONTAINER_READINESS);
    const postReportContainer = companyRow.find(CONTAINER_POST_MIGRATION_REPORT);
    const otherContainer = companyRow.find(CONTAINER_OTHER);

    // Result col
    if (details.MigrationFailed) {
      let template = document.getElementById(TEMPLATE_RESULT_MIGRATION_FAILED_BUTTON).innerHTML;
      template = template.replace('{{failedText}}', details.MigrationFailedText);
      postReportContainer.html(template);
    } else {
      postReportContainer.empty();
    }

    // Other col
    let template = document.getElementById(TEMPLATE_DEACTIVATE_AND_ALREADY_MIGRATED_LINK).innerHTML;
    otherContainer.html(template);

    // Readiness & Next Step cols
    switch (details.ReadinessStatus) {
      case READINESS_STATUS_QUEUED:
        template = document.getElementById(TEMPLATE_NEXT_STEP_TEXT).innerHTML;
        template = template.replace('{{nextStepText}}', 'Refresh');
        nextStepContainer.html(template);
        readinessContainer.empty();
        break;

      case READINESS_STATUS_PENDING:
        template = document.getElementById(TEMPLATE_NEXT_STEP_TEXT).innerHTML;
        template = template.replace('{{nextStepText}}', 'Refresh');
        readinessContainer.empty();
        break;

      case READINESS_STATUS_NOT_READY:
        template = document.getElementById(TEMPLATE_NEXT_STEP_REPORT).innerHTML;
        template = template.replace('{{reportUrl}}', details.ReadinessReportUrl);
        nextStepContainer.html(template);

        template = document.getElementById(TEMPLATE_READINESS_ERRORS).innerHTML;
        template = template.replace('{{warningsText}}', this.createWarningsText(details));
        template = template.replace('{{errorsText}}', this.createErrorsText(details));
        readinessContainer.html(template);

        break;

      case READINESS_STATUS_READY:
        template = document.getElementById(TEMPLATE_NEXT_STEP_MIGRATE).innerHTML;
        nextStepContainer.html(template);

        if (details.ReadinessWarningsCount > 0) {
          if (readinessContainer.find('.readiness-warning-container').length === 0) {
            // Only add the warning container if not already added
            // Prevents disrupting tooltip when updating UI
            template = document.getElementById(TEMPLATE_READINESS_WARNING).innerHTML;
            template = template.replace('{{warningsText}}', this.createWarningsText(details));
            template = template.replace('{{reportUrl}}', details.ReadinessReportUrl);
            readinessContainer.html(template);
          }
        } else {
          template = document.getElementById(TEMPLATE_READINESS_IS_READY).innerHTML;
          readinessContainer.html(template);
        }

        break;

      case READINESS_STATUS_STALE:
      default:
        template = document.getElementById(TEMPLATE_NEXT_STEP_REFRESH).innerHTML;
        nextStepContainer.html(template);
        readinessContainer.empty();
        break;
    }
  }

  createWarningsText(details) {
    return details.ReadinessWarningsCount ? `${details.ReadinessWarningsCount} warning(s)` : '';
  }

  createErrorsText(details) {
    let errors = '<div>';
    if (details.ReadinessCompanyErrors > 0) errors += 'company configuration <br>';
    if (details.ReadinessUnsupportedPlans > 0)
      errors += `${details.ReadinessUnsupportedPlans} plans<br>`;
    if (details.ReadinessUnsupportedEmployees > 0)
      errors += `${details.ReadinessUnsupportedEmployees} employees<br>`;
    if (details.ReadinessUnsupportedEnrollments > 0)
      errors += `${details.ReadinessUnsupportedEnrollments} enrollments<br>`;
    if (details.ReadinessUnsupportedManageChanges > 0)
      errors += `${details.ReadinessUnsupportedManageChanges} unresolved todos (enrollments)<br>`;
    if (details.ReadinessUnsupportedPartners > 0)
      errors += `${details.ReadinessUnsupportedPartners} unsupported partners`;
    errors += '</div>';
    return errors;
  }

  updatePostReport(details, postReportContainer) {
    let template = null;
    switch (details.PostMigrationReportStatus) {
      case REPORT_STATUS_GENERATING:
        template = document.getElementById(TEMPLATE_POST_MIGRATION_REPORT_GENERATING).innerHTML;
        postReportContainer.html(template);
        break;
      case REPORT_STATUS_COMPLETE:
        template = document.getElementById(TEMPLATE_POST_MIGRATION_REPORT_BUTTON).innerHTML;
        template = template.replace('{{reportUrl}}', details.PostMigrationReportUrl);
        postReportContainer.html(template);
        break;
      case REPORT_STATUS_FAILED:
        template = document.getElementById(TEMPLATE_POST_MIGRATION_REPORT_FAILED).innerHTML;
        postReportContainer.html(template);
        break;
      default:
        postReportContainer.empty();
        break;
    }
  }
}

import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['dropdown'];

    toggle() {
      $(this.dropdownTarget).hasClass('is-active') ? this.hide() : this.show();
    }

    show() {
      $(this.dropdownTarget).addClass('is-active');
      document.addEventListener('click', this.clickEvent, true);
    }

    hide() {
      $(this.dropdownTarget).removeClass('is-active');
      document.removeEventListener('click', this.clickEvent, true);
    }

    clickEvent = event => {
      const isClickInside = this.element.contains(event.target);
      if (!isClickInside) this.hide();
    };
}

import { Controller } from 'stimulus';
import $ from 'jquery';
import 'tablesorter';

export default class extends Controller {
  initialize() {
    $('#report').tablesorter({
      showProcessing: true,
      headerTemplate: '{content} {icon}', // Add icon for various themes
      sortReset: true,
      sortMultiSortKey: 'bubbles',
      widgets: ['zebra', 'stickyHeaders'],
      widgetOptions: {
        // extra class name added to the sticky header row
        stickyHeaders: '',
        // number or jquery selector targeting the position:fixed element
        stickyHeaders_offset: 0,
        // added to table ID, if it exists
        stickyHeaders_cloneId: '-sticky',
        // trigger "resize" event on headers
        stickyHeaders_addResizeEvent: true,
        // The zIndex of the stickyHeaders, allows the user to adjust this to their needs
        stickyHeaders_zIndex: 2,
        // jQuery selector or object to attach sticky header to
        stickyHeaders_attachTo: null,
        // jQuery selector or object to monitor horizontal scroll position (defaults: xScroll > attachTo > window)
        stickyHeaders_xScroll: null,
        // jQuery selector or object to monitor vertical scroll position (defaults: yScroll > attachTo > window)
        stickyHeaders_yScroll: null,
        // scroll table top into view after filtering
        stickyHeaders_filteredToTop: true,
        zebra: ['normal-row', 'alt-row']
      }
    });
  }
}

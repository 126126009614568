import { Controller } from 'stimulus';
import $ from 'jquery';
import Dropzone from 'dropzone';

export default class extends Controller {
  static targets = ['dropzone'];

  // TODO: ease library colors present and may need to be remapped

  initialize() {
    const self = this;

    Dropzone.autoDiscover = false; // eslint-disable-line

    const maxFileCount = 25;
    const maxFileSize = 1024;
    const successMessage = ' of your files have been successfully uploaded.';
    const failureMessage =
      ' of your files could not be uploaded. Please check the error messages below to see what happened.';

    let acceptedFiles = '';
    let singleFileUpload = false;
    let addPartner = false;
    let successFiles = 0;
    let failureFiles = 0;

    $('.upload-background').css('display', 'flex');
    $('.c-upload-results .c-upload-header').css('display', 'none');
    $('div.c-upload-header > div.c-upload-results--success > span').text(`None${successMessage}`);
    $('div.c-upload-header > div.c-upload-results--failure > span').text('');

    if (this.element.dropzone) {
      this.element.dropzone.destroy();
    }

    // new dropzone
    const dropzone = new Dropzone(this.element, {
      previewsContainer: '#previews',
      acceptedFiles:
        '.csv,.doc,.docx,.gif,.jpe,.jpeg,.jpg,.pdf,.png,.ppt,.pptx,.psd,.rtf,.txt,.xls,.xlsx',
      maxFiles: maxFileCount, // Files to upload at one time
      maxFileSize: maxFileSize, // MB
      parallelUploads: 3,
      createImageThumbnails: false,
      thumbnailWidth: 50,
      thumbnailHeight: 50,
      thumbnailMethod: 'crop',
      clickable: '.browse-button',
      dictDefaultMessage: '',
      dictInvalidFileType: 'Invalid file type',
      dictMaxFilesExceeded: `Only ${maxFileCount} files are allowed at a time`,
      previewTemplate: `
                    <div class='dz-preview dz-file-preview'>
                        <div class='dz-image'>
                            <img data-dz-thumbnail />
                        </div>
                        <div class='dz-details'>
                            <div class='dz-size'>
                                <span data-dz-size></span>
                            </div>
                            <div class='dz-filename'>
                                <span data-dz-name></span>
                            </div>
                        </div>
                        <div class='error-message'></div>
                        <div class='dz-progress'>
                            <span class='dz-upload' data-dz-uploadprogress></span>
                        </div>
                        <div class='dz-error-message'>
                            <span data-dz-errormessage></span>
                        </div>
                        <div class='dz-success-mark'>
                            <svg width='24px' height='24px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><circle fill='#39C38D' cx='12' cy='12' r='12'/><path stroke='#FFF' stroke-width='2' stroke-linecap='round' d='M18 8L10 16m0 0L7 13'/></svg>
                        </div>
                        <div class='dz-error-mark'>
                            <svg width='24px' height='24px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><circle fill='#FF6961' cx='12' cy='12' r='12'/><path stroke='#FFF' stroke-linecap='round' stroke-width='2' d='M15 9 L9 15M9 9 L15 15'/></svg>
                        </div>
                    </div>`
    });

    dropzone.on('error', function(file, message) {
      failureFiles++;
      $('div.c-upload-header > div.c-upload-results--failure > span').text(
        failureFiles + failureMessage
      );

      dropzone.options.error(file, message);

      if (file.previewElement) {
        $(file.previewElement)
          .find('.error-message')
          .text(`* ${message}`);
      }
    });

    dropzone.on('queuecomplete', () => {
      if (!singleFileUpload && acceptedFiles.length > 0) {
        if (addPartner === true) {
          window.saveFieldValue('partnerDocumentIds', acceptedFiles);
        } else {
          window.saveFieldValue('folderDocumentIds', acceptedFiles);
        }
      }
    });

    dropzone.on('sending', () => {
      // Check for single file upload and success
      const queued = dropzone.getQueuedFiles().length;
      const uploading = dropzone.getUploadingFiles().length;

      singleFileUpload = queued + uploading === 1;

      if (singleFileUpload) {
        $('.upload-background').css('display', 'none');
        $('div.c-upload-results .c-upload-header').css('display', 'flex');
      }
    });

    dropzone.on('complete', function(file) {
      file.previewElement.classList.remove('dz-success');

      if (file == null || file.xhr == null || file.xhr.responseText == null) {
        return;
      }

      file.previewElement.classList.remove('dz-success');

      const res = JSON.parse(file.xhr.responseText);

      $('#dropzoneDoneButton').show();

      if (res.error) {
        failureFiles++;
        $('div.c-upload-header > div.c-upload-results--failure > span').text(
          failureFiles + failureMessage
        );

        dropzone.options.error(file, res.error);

        if (file.previewElement) {
          $(file.previewElement)
            .find('.error-message')
            .text(`* ${res.message}`);
        }
      } else {
        successFiles++;

        $('div.c-upload-header > div.c-upload-results--success > span').text(
          successFiles + successMessage
        );

        file.previewElement.classList.add('dz-success');
        dropzone.options.success(file);

        if (res.hasFolders === true || res.addPartnerPrompt === true) {
          acceptedFiles += `${res.id},`;
        }

        if (singleFileUpload) {
          if (res.redirectUrl.length > 0) {
            window.open(res.redirectUrl, '_blank');
          }

          if (res.addInfoPrompt === true) {
            window.saveFieldValue('documentDataId', res.id);
          }
        }

        if (res.reload) {
          window.location.reload();
        }

        addPartner = res.addPartnerPrompt;
      }
    });

    dropzone.on('dragenter', () => {
      self.showDropZone();
    });

    dropzone.on('dragleave', () => {
      self.hideDropZone();
    });

    dropzone.on('drop', event => {
      event.preventDefault();

      self.hideDropZone();

      $('.upload-background').css('display', 'none');
      $('div.c-upload-results .c-upload-header').css('display', 'flex');
    });
  }

  showDropZone = () => {
    $(this.element).addClass('drag-over');
    $('.browse-button').css('pointer-events', 'none');
  };

  hideDropZone = () => {
    $(this.element).removeClass('drag-over');
    $('.browse-button').css('pointer-events', 'unset');
  };
}

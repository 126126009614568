import $ from 'jquery';
import { Controller } from 'stimulus';
import debounce from 'lodash.debounce';
import { createElement } from '../lib/utils';

export default class extends Controller {
  static targets = ['row', 'checkbox'];

  initialize() {
    this.debouncedSearch = debounce(this.search, 300);
    this.isUsingCarrierColumn = !!this.element.querySelector('[data-is-carrier-enabled]');
  }

  /**
   * Performs a search based on the search string provided.
   * @param {*} event - The event that called this function.
   */
  search(event) {
    const query = event.target.value;

    const searchFormInput = document.getElementById('searchFormInput');
    searchFormInput.value = query;
    window.postForm('searchForms', this.constructFormsSelectTable.bind(this));
  }

  /**
   * Performs a search based on the filter used.
   * @param {*} event - The event that called this function.
   */
  handleSearchFilterChange({ target }) {
    if (target && target.dataset && target.dataset.hiddeninputid) {
      const input = document.getElementById(target.dataset.hiddeninputid);

      if (input) {
        input.value = target.value;
        window.postForm(input.form.id, this.constructFormsSelectTable.bind(this));
      }
    }
  }

  /**
   * Handles when a form selection change event is fired.
   * @param {*} event - The event that called this function.
   */
  formSelectionChange(event) {
    const $target = $(event.target);
    if ($target.is('a') || $target.parent().is('a')) return;

    const $row = $(event.currentTarget);
    const $cb = $row.find('input[type="checkbox"]').first();
    const isNotChecked = !$cb.is(':checked');
    const formId = $cb.attr('id');
    const selectedForms = document.getElementById('formsSelected');

    if (!selectedForms) return;

    let forms = selectedForms.value.split(',');

    if (isNotChecked) {
      if (forms.indexOf(formId) === -1) {
        forms.push(formId);
      } else {
        return;
      }
    } else {
      forms = forms.filter(f => f !== formId);
    }

    selectedForms.value = forms;
  }

  /**
   * Builds an HTML table with all form results based on the search criteria.
   * @param {*} response - The server-side response containing the form data.
   */
  constructFormsSelectTable({ error, content }) {
    if (error) return;

    const formsTable = document.getElementById('selectableForms');
    const tableBody = formsTable.querySelector('.c-list-view__body');
    tableBody.innerHTML = '';

    const messageElement = document.getElementById('searchMessage');

    if (content) {
      if (content.showMessage) {
        messageElement.style = '';
      } else {
        messageElement.style = 'visibility: hidden;';
      }

      const { forms } = content;
      if (forms && forms.length > 0) {
        for (let i = 0; i < forms.length; i++) {
          const json = forms[i];
          const row = this.addFormRow(json);
          tableBody.appendChild(row);
        }
      }
    }
  }

  /**
   * Create a simple table column with a display value.
   * @param {string} value display text to be inserted into a column span.
   * @param {object} attributes element attributes to be applid to the column container
   * @returns {Element}
   */
  createColumn(value, attributes) {
    const container = createElement('div', attributes);
    const span = createElement('span');
    span.innerText = value;

    container.appendChild(span);

    return container;
  }

  /**
   * Adds a row to the table of search results.
   * @param {*} json - The JSON response containing the form data.
   */
  addFormRow({ Id, Selected, Url, Name, TagNames, CarrierName, State, FormId }) {
    const parentRow = createElement('div', {
      class: 'c-list-view__item c-list-view__item--selectable'
    });

    const dataActionRow = createElement('div', {
      'data-action': 'click->list#select',
      'data-target': 'list.row'
    });

    const eRow = createElement('div', {
      class: 'e-row',
      'data-action': 'click->forms#formSelectionChange'
    });

    const formNameColumn = createElement('div', {
      'data-spread': 'wide',
      'data-width': this.isUsingCarrierColumn ? '6' : '7'
    });

    const formNameLabel = createElement('label', { class: 'c-checkbox checkbox' });

    const formNameInput = createElement('input', {
      class: 'c-checkbox__input',
      'data-target': 'list.checkbox',
      id: Id,
      value: 1,
      type: 'checkbox',
      name: Id
    });

    if (Selected) formNameInput.setAttribute('checked', '');

    const formNameCheckboxSpan = createElement('span', {
      class: 'c-checkbox__box checkbox'
    });

    formNameLabel.appendChild(formNameInput);
    formNameLabel.appendChild(formNameCheckboxSpan);
    formNameColumn.appendChild(formNameLabel);

    const formNameLink = createElement('a', {
      target: '_blank',
      href: Url
    });
    formNameLink.innerText = Name;
    formNameColumn.appendChild(formNameLink);

    this.addFormTags(formNameColumn, TagNames);

    const formCarrierColumn = this.isUsingCarrierColumn
      ? this.createColumn(CarrierName, { 'data-spread': 'wide', 'data-width': '2' })
      : null;

    const formStateColumn = this.createColumn(State, { 'date-spread': 'wide', 'data-width': '1' });
    const formIdColumn = this.createColumn(FormId, {
      'date-spread': 'wide',
      'data-width': this.isUsingCarrierColumn ? '3' : '4'
    });

    eRow.appendChild(formNameColumn);
    eRow.appendChild(formStateColumn);
    if (formCarrierColumn) eRow.appendChild(formCarrierColumn);
    eRow.appendChild(formIdColumn);
    dataActionRow.appendChild(eRow);
    parentRow.appendChild(dataActionRow);

    return parentRow;
  }

  /**
   * Adds tags to the table row for search results.
   * @param {*} parentElement - The HTML parent element to add the tag to.
   * @param {*} tagNames - The list of tag names to add to the row.
   */
  addFormTags(parentElement, tagNames) {
    if (tagNames && tagNames.length > 0) {
      for (let i = 0; i < tagNames.length; i++) {
        const formTagSpan = createElement('span', { class: 'c-tag' });
        formTagSpan.innerText = tagNames[i];

        parentElement.appendChild(formTagSpan);
      }
    }
  }
}

import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.constants = {
      classArrow: 'c-brick__arrow',
      classArrowDown: 'c-brick__arrow--down',
      classFilesContainer: 'c-brick__files-container'
    };
  }

  dropdown(e) {
    const $currentTarget = $(e.currentTarget);

    $currentTarget
      .children(`.${this.constants.classArrow}`)
      .toggleClass(this.constants.classArrowDown);
    $currentTarget
      .parent()
      .siblings(`.${this.constants.classFilesContainer}`)
      .toggle();
  }
}

export default function defineBoxShadow(svg, id, config = { x: 0, y: 2, blurRadius: 2 }) {

  const filter = svg
    .append('svg:defs')
    .append('svg:filter')
    .attr('id', id);

  filter
    .append('svg:feGaussianBlur')
    .attr('in', 'SourceAlpha')
    .attr('stdDeviation', config.blurRadius) // stdDeviation is how much to blur
    .attr('result', 'blurOut');

  filter
    .append('svg:feOffset')
    .attr('in', 'blurOut')
    .attr('dx', config.x)
    .attr('dy', config.y)
    .attr('result', 'offsetOut'); // how much to offset

  const feMerge = filter.append('feMerge');

  feMerge.append('feMergeNode').attr('in', 'offsetOut');
  feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
};

class Person {
    constructor(item) {
        this.id = item.id;
        this.avatar = item.image_url;
        this.fullName = `${item.first_name} ${item.last_name}`;
        this.firstName = item.first_name;
        this.lastName = item.last_name;
        this.title = item.job_title;
        this.department = item.department_name;
        this.totalReports = item.children.length;
        this.hasChild = item.children.length > 0;
        this.hasManager = item.manager_id !== '';
        this.managerId = item.manager_id;
        this.children = item.children ? item.children.map(child => new Person(child)) : [];
        this.initials = `${item.first_name.charAt(0)}${item.last_name.charAt(0)}`;
        this.jobTitle = item.job_title;
    }
}

export default Person;

/* eslint-disable no-undef */
import { Controller } from 'stimulus';
import $ from 'jquery';
import 'croppie';

export default class extends Controller {
    static targets = ['input'];

    initialize() {
      // Profile Image Upload employee/Nav.cs
      $('#chooseProfileImage').change(function() {
        // eslint-disable-next-line no-undef
        ease.Modals.openModal('profileImage');
      });

      this.croppie = new Croppie(this.element, {
            // eslint-disable-line
        viewport: {
          width: 200,
          height: 200,
          type: 'square'
        }
      });

      window.addEventListener('loadImage', this.modifyImage);
    }

    modifyImage = () => {
      this.croppie.bind({
        url: this.data.get('url')
      });
    };

    loadImage = () => {
      const { files } = this.inputTarget;

      if (!files || files.length === 0) return;
      if (!files[0].type.substring('image')) {
            alert('Selected file must be an image'); // eslint-disable-line
      }

      const file = files[0];
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          this.croppie.bind({
            url: reader.result
          });
        },
        false
      );

      reader.readAsDataURL(file);
    };

    saveCroppedImage() {
      this.croppie.result('canvas').then(data => {
        $(this.element)
          .find('.cr-image')
          .val(data)
          .get(0)
          .dispatchEvent(new Event('change'));
      });
    }
}

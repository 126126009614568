import { Controller } from 'stimulus';
import $ from 'jquery';

ease.datepicker = ease.datepicker || {};
ease.datepicker.settings = {
	inline: true,
	//hack to make the datepicker display correctly in a modal when the page is scrolled
	beforeShow: function (input, inst) {
		$.datepicker._pos = $.datepicker._findPos(input);
		$.datepicker._pos[1] += input.offsetHeight;
		var isInModal = $(input).parents('.c-modal').length;
		var isScrolled = $(window).scrollTop() > 0;
		if (isInModal && isScrolled) {
			$.datepicker._pos[1] -= $(window).scrollTop();
		}
	},
	onSelect: function (d, i) {
		if (d !== i.lastVal) {
			this.dispatchEvent(new Event('change'));
		}
	}
};

export default class extends Controller {
  initialize() {
		$('.ease-datepicker').datepicker(ease.datepicker.settings);
	}
}
/* global ease */

/*
 * This helper function is used to paste at caret position
 * from within a contenteditable div
 */
const insertAtCaret = (Element.prototype.insertAtCaret = text => {
  let range;
  let selection;

  if (window.getSelection) {
    selection = window.getSelection();
    range = selection.getRangeAt(0);
    range.deleteContents();

    const textNode = document.createTextNode(text);

    range.insertNode(textNode);
    range.setStartAfter(textNode);
    selection.removeAllRanges();
    selection.addRange(range);
  } else if (document.selection && document.selection.createRange) {
    document.selection.createRange().text = text;
  }
});

/*
 * Searches the the query in text regardless of case
 */
const fuzzySearch = (text, query) => {
  const haystack = text.toLowerCase();
  const needle = query.toLowerCase();
  return haystack.indexOf(needle) > -1;
};

/*
 * Searches each term in query in text regardless of order & case
 */
const fuzzySearchTerms = (text, query) => {
  const haystack = text.replace(/[(),*-/# ]/g, '').toLowerCase();
  const terms = query
    .replace(/[(),*-/#]/g, '')
    .toLowerCase()
    .split(' ');
  for (let i = 0; i < terms.length; i++) {
    if (haystack.indexOf(terms[i]) === -1) return false;
  }
  return true;
};

const doAnyWordsBeginWith = (text, query) => {
  const haystack = text.toLowerCase();
  const needle = query.toLowerCase();
  return haystack.startsWith(needle) || haystack.indexOf(` ${needle}`) > -1;
};

const isLandscape = () => {
  const landscape = window.orientation === 90 || window.orientation === -90;
  if (landscape) window.scrollTo(0, 1);
  return landscape;
};

const isMobile = () => {
  let check = false;
  // eslint-disable-next-line
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const isIE11 = () => !!(window.MSInputMethodContext && document.documentMode);

const isEdge = () => /Edge/.test(navigator.userAgent);

/**
 * Clears the value from an input or select on the page. This does not necessarily save the empty value to the db
 * @param {element} target
 */
const clearInput = target => {
  if (target.nodeName === 'INPUT') {
    // eslint-disable-next-line no-param-reassign
    target.innerHTML = '';
  } else if (target.nodeName === 'SELECT') {
    // eslint-disable-next-line no-param-reassign
    target.selectedIndex = 0;
    const selectId = target.id;
    const rowId = `${selectId}Row`;
    // TODO: do not rely on presentation classes for logic. Use either a `.js-` class or another element attribute
    const selectValue = document.querySelector(`#${rowId} .c-input__value`);
    selectValue.innerHTML = '';
  }
};

/**
 * add an attribute to a DOM node without overwriting existing values
 * @param {node} targetNode
 * @param {string} attribute
 * @param {string} value
 */
const addAttribute = (targetNode, attribute, value) => {
  const currentValue = targetNode.getAttribute(attribute);
  if (currentValue && currentValue.indexOf(value) > -1) {
    return;
  }
  const newValue = currentValue ? `${currentValue} ${value}` : value;
  targetNode.setAttribute(attribute, newValue);
};

/**
 * an implementation of addAttribute() specifically for adding data-actions
 * @param {node} targetNode
 * @param {string} action
 */
const addDataAction = (targetNode, action) => {
  addAttribute(targetNode, 'data-action', action);
};

/**
 * format a string representation of a float as currency
 * @param  {string} string
 * @return {string}
 */
const formatAsCurrency = string => {
  return `$${parseFloat(ease.page.sanitizeDecimal(string))
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

/**
 * round a string representation of a number to 2 decimal places
 * @param  {string} string
 * @return {string}
 */
const roundToTwoDigits = string =>
  (Math.round(ease.page.sanitizeDecimal(string) * 100) / 100).toFixed(2);

/**
 * truncate a string representation of a number to an int
 * @param  {string} string
 * @return {number}
 */
const toInt = string => Math.trunc(ease.page.sanitizeDecimal(string));

const formContainsErrors = formId => {
  const form = document.getElementById(formId);

  const filterVisibleInputs = collection => {
    if (!collection) return false;
    const visibleInputs = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const element of collection) {
      // since we use multiple methods to hide elements the following is a reliable method to check for visibility
      if (element.offsetWidth && element.offsetHeight) {
        visibleInputs.push(element);
      }
    }
    return visibleInputs.length > 0;
  };

  // TODO: do not rely on presentation classes for logic. Use either a `.js-` class or another element attribute
  const errorFound = filterVisibleInputs(form.getElementsByClassName('c-input--error'));

  return errorFound;
};

/**
 * Remove a specified DOM Node. IE Compatable.
 * @param {Node} el - Node to be remove from the DOM
 */
const removeElement = el => {
  const { parentElement } = el;

  if (parentElement) {
    parentElement.removeChild(el);
  }
};

/**
 * Create an element with assigned attributes. IE Compatable.
 * @param {string} tagName string representation of the DOM node tag name
 * @param {object} attributes element attributes for the given DOM node.
 * @returns {Element}
 */
const createElement = (tagName = 'div', attributes = {}) => {
  const el = document.createElement(tagName);

  Object.keys(attributes).forEach(key => el.setAttribute(key, attributes[key]));

  return el;
};

export {
  addAttribute,
  addDataAction,
  clearInput,
  createElement,
  doAnyWordsBeginWith,
  formatAsCurrency,
  formContainsErrors,
  fuzzySearch,
  fuzzySearchTerms,
  insertAtCaret,
  isEdge,
  isIE11,
  isLandscape,
  isMobile,
  removeElement,
  roundToTwoDigits,
  toInt
};

// error_controller.js
import { Controller } from 'stimulus';
import Message from '../lib/message';

export default class extends Controller {
  static targets = ['container'];

  initialize() {
    this.errorMessage = new Message(this.containerTarget, 'c-message c-message--error');
    this.errorMessage.updatePosition();
  }

  showErrorMessage() {
    this.errorMessage.updatePosition();
  }
}

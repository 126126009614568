import 'jquery-ui/ui/scroll-parent';
import { Controller } from 'stimulus';
import $ from 'jquery';
import debounce from 'lodash.debounce';
import { doAnyWordsBeginWith, isIE11, isEdge, removeElement } from '../lib/utils';
import { keyCodes } from '../lib/global';

export default class extends Controller {
  static targets = [
    'component',
    'container',
    'drawer',
    'drawerOption',
    'drawerOptions',
    'handle',
    'label',
    'labelContainer',
    'labelMask',
    'optionElement',
    'pill',
    'searchField',
    'selectElement',
    'inputValue'
  ];

  initialize() {
    this.$component = $(this.element);
    this.idPrefix = this.$component.attr('data-id');
    this.modifier = this.$component.attr('data-type-modifier');
    // the === 'true's are required because ease-app will add the blank attributes even
    // if they are false which will evaluate to true here
    this.isSearchEnabled = this.$component.attr('data-search-enabled') === 'true';
    this.isEmployee = this.modifier === 'employee';
    this.isSmall = this.modifier === 'small';
    this.isFilter = this.modifier === 'filter';
    this.isMultiselectEnabled =
      this.$component.attr('data-multiple-select-enabled') === 'true' && !this.isSmall;
    this.isDeselectEnabled =
      this.$component.attr('data-deselect-enabled') === 'true' ||
      this.$component.attr('data-multiple-select-enabled') === 'true';
    this.isReadOnly = this.$component.attr('data-readonly') === 'true';
    this.errorText = this.$component.attr('data-error-text');
    this.errorHREF = this.$component.attr('data-error-href');
    this.errorLinkText = this.errorHREF
      ? this.$component.attr('data-error-link-text') || 'learn more'
      : '';
    this.isRequired = this.$component.attr('data-required') === 'true';
    this.labelText = this.$component.attr('data-label');

    this.valuePlaceholder = this.$component.attr('data-value-placeholder') || 'Select';
    this.searchPlaceholder = this.$component.attr('data-search-placeholder');

    this.suggestionString = '';
    this.suggestionTimeoutHandle = {};

    this.debounceHandleInput = debounce(this.handleInput, 200);

    this.selectedIndex = -1;
    this.highlightedIndex = -1;

    // Quill select closes the drawer but does not select a value
    this.noselect = this.$component.attr('data-noselect') === 'true';
    this.quill = this.noselect;

    this.searchUrl = this.$component.attr('data-searchUrl');

    this.inspectSelectElement();

    this.computeOptionsArray();
    this.checkErrorState();
    if (this.labelText && this.isEmployee) {
      this.applyLabelMask();
    }
    if (!this.isReadOnly) {
      this.attachMutationObservers();
    }

    this.initializeOptions();
    this.updateValue();

    this.MutationObserver =
      window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

    this.isIE11 = isIE11();
    this.isEdge = isEdge();

    if (this.hasInitialValue === undefined) {
      // determine if any option is selected on first call to Initialize() only as repeated
      // calls within the controller will cause this to become out of sync with the initial page load value.
      this.hasInitialValue = !!document.querySelector(`#${this.idPrefix} option:checked`);
    }
  }

  inspectSelectElement() {
    $(this.optionElementTargets).each((index, optionElement) => {
      if (index > 0 && (optionElement.value === '' || optionElement.textContent === '')) {
        // this is a blank option that is not the first child of the select, remove it
        optionElement.remove();
      }
    });
  }

  computeOptionsArray() {
    const { idPrefix } = this;
    this.optionsArray = [];
    this.optionsArray.validOptions = 0;
    $(this.optionElementTargets).each((index, optionsElement) => {
      const item = {
        element: optionsElement,
        text: optionsElement.text,
        selected: optionsElement.selected,
        highlighted: false,
        modified: false,
        visible:
          optionsElement.value !== null &&
          optionsElement.value !== undefined &&
          optionsElement.value !== '' &&
          optionsElement.text !== '',
        hiddenDisplay: optionsElement.hidden,
        value: optionsElement.value,
        disabled: optionsElement.disabled,
        id: `${idPrefix}${String(index)}`
      };
      this.optionsArray.push(item);
      if (optionsElement.text !== '') this.optionsArray.validOptions++;
    });
  }

  checkErrorState() {
    $(this.element).attr({
      'data-controller': `select${!this.isEmployee && !this.isReadOnly ? ' error' : ''}`,
      'data-action': `focus->select#handleFocus mousedown->select#handleClick keydown->select#handleKeydown
                ${!this.isEmployee ? ' focus->error#showErrorMessage' : ''}
                ${this.quill ? ' keydown->quill#handleKeyDown' : ''}`
    });

    if (this.errorText) {
      $(this.containerTarget).toggleClass('c-input--error', true);
      $(this.containerTarget).toggleClass('has-error', true);
      $(this.containerTarget).attr({
        'data-info': this.errorText,
        'data-label': this.errorLinkText,
        'data-href': this.errorHREF
      });
    }
  }

  applyLabelMask() {
    const maskElement = $('<div />', {
      class: 'c-select__label-mask',
      'data-target': 'select.labelMask',
      'data-action': 'click->select#handleClickMask'
    });
    maskElement.appendTo($(this.labelTarget));
  }

  attachMutationObservers() {
    if (this.optionObserver === undefined) {
      this.optionObserver = new MutationObserver(() => this.handleOptionMutation());
      this.optionObserver.observe(this.selectElementTarget, {
        childList: true,
        subtree: true
      });
    }

    if (this.errorObserver === undefined) {
      this.errorObserver = new MutationObserver(mutations => {
        mutations.forEach(() => {
          this.errorText = $(this.element).attr('data-error-text');
          this.errorHREF = $(this.element).attr('data-error-href');
          this.errorLinkText = this.errorHREF
            ? $(this.element).attr('data-error-link-text') || 'learn more'
            : '';

          const $errorMessage = $(this.handleTarget).children('.c-message--error');
          $errorMessage.empty();

          if (this.errorText || this.errorHREF) {
            $errorMessage.text(this.errorText);
            $(`<a class='c-message__link' href=${this.errorHREF} target='_blank'>
                          ${this.errorLinkText}
                      <a/>`).appendTo($errorMessage);
          }
        });
      });
      this.errorObserver.observe(this.element, {
        attributes: true,
        attributeFilter: ['data-error-text', 'data-error-link-text', 'data-error-href']
      });
    }
  }

  initializeOptions() {
    this.isEmpty = this.optionsArray.validOptions === 0 && !this.searchUrl;
    $(this.handleTarget).toggleClass('is-empty', this.isEmpty);
    $(this.handleTarget).toggleClass('is-read-only', this.isReadOnly);

    if (this.isReadOnly || this.isEmpty) {
      return;
    }

    let optionsString = '';

    this.drawerOptionsTarget.innerHTML = '';
    this.optionsArray.forEach((option, index) => {
      if (option.text === '' || option.hiddenDisplay) {
        // for each blank option element in <select>
        // append a blank hidden option to the drawer to maintain index parity
        const blankOption = `<div data-target='select.drawerOption' id='${option.id}' data-index=${index} hidden='true'></div>`;

        optionsString += blankOption;
      } else {
        let classString = 'c-select__option';
        if (option.selected) {
          this.selectedIndex = index;
          classString += ' c-select__option--selected';
        }
        if (option.disabled) classString = 'c-select__notice';
        const optionString = `<div role='option' aria-selected='${
          option.selected
        }' class='${classString}' data-action='mouseenter->select#handleMouseenterOption mouseup->select#handleMouseupOption ${
          this.quill ? 'click->quill#handleMouseDown' : ''
        }' data-target='select.drawerOption ${
          this.quill ? ' quill.drawerOption' : ''
        }' data-selected='${option.selected}' id='${
          option.id
        }' data-index='${index}'><span class='option-text' text='${
          option.text
        }' data-index='${index}'>${option.text}</span></div>`;

        optionsString += optionString;
      }
    });

    // checking if noticeAdded is undefined lets us only include this notice on the initial render
    // and not leave it in place when the user filters results by searching
    if (this.searchUrl && typeof this.noticeAdded === 'undefined') {
      const notice =
        '<div data-target="select.drawerOption" class="c-select__notice">Search to show results</div>';
      optionsString += notice;
    }
    this.noticeAdded = true;
    this.drawerOptionsTarget.innerHTML = optionsString;
  }

  /**
   * Create a hidden backing input without an id.
   * Allows for submission of empty values when no user-specified
   * option selection is detected in a post form submit.
   */
  insertHiddenBackingField() {
    const input = document.createElement('input');
    input.name = this.idPrefix;
    input.hidden = true;
    input.type = 'hidden';

    $(input).insertBefore(this.$component);
  }

  /**
   * Include or remove a hidden backing field to allow the sending
   * of empty values for multiselect fields in post form data.
   *
   * @param {boolean} shouldInsert - Determines creation or removal of backing field.
   */
  toggleMultiSelectBackingField(shouldInsert) {
    const hiddenBackingInput = document.querySelector(`input[name="${this.idPrefix}"]`);

    if (shouldInsert) {
      if (!hiddenBackingInput) this.insertHiddenBackingField();
    } else if (hiddenBackingInput) {
      removeElement(hiddenBackingInput);
    }
  }

  updateValue() {
    $(this.inputValueTarget).attr('data-placeholder', this.valuePlaceholder);

    if (this.isMultiselectEnabled) {
      const selectedCount = this.optionsArray.filter(x => x.selected && x.text).length;

      if (selectedCount > 0) {
        this.toggleMultiSelectBackingField(false);
      } else if (selectedCount === 0 && this.hasInitialValue) {
        this.toggleMultiSelectBackingField(true);
      }

      if (this.isFilter) {
        if (selectedCount > 1) {
          $(this.labelTarget).text(`${this.labelText} (${selectedCount})`);
        } else {
          $(this.labelTarget).text(this.labelText);
        }
      }
    }

    if (this.showPills) {
      $(this.inputValueTarget).empty();
      this.optionsArray.forEach((option, index) => {
        if (option.selected) {
          this.createPill(index);
        }
      });
    } else {
      $(this.inputValueTarget).removeClass('c-select__value--expanded');
      let info = '';

      this.optionsArray.forEach(option => {
        if (option.selected) {
          if (info.length > 0) {
            info += ', ';
          }
          info += option.text;
        }
      });

      $(this.inputValueTarget).text(info);
    }
  }

  createPill(index) {
    const pill = $('<div />', {
      class: 'c-input__pill',
      'data-target': 'select.pill'
    });
    pill.appendTo(this.inputValueTarget);
    $('<span/>', {
      class: 'c-input__pillText',
      html: $(this.selectElementTarget[index]).html()
    }).appendTo(pill);
    if (this.isDeselectEnabled && this.isMultiselectEnabled) {
      $(
        `<svg class="c-input__pillEx" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" data-action="click->select#handleClickPillEx" data-index="${index}">
                    <use xlink:href="#icon_close"></use>
                </svg>`
      ).appendTo(pill);
    }
  }

  scrollToKeepHighlightedVisible(index) {
    const $target = $(this.drawerOptionTargets).eq(index);
    this.setHighlightByIndex(index, true);
    if (!($target[0] instanceof HTMLElement)) return;

    const $scrollParent = $(this.drawerOptionsTarget);
    if (!($scrollParent[0] instanceof HTMLElement)) return;

    const $targetOffset = $target.offset().top - $scrollParent.offset().top;

    if ($targetOffset < 0) {
      // scrolling to available space above
      $scrollParent.scrollTop($targetOffset + $scrollParent.scrollTop());
    } else if ($targetOffset + $target.outerHeight() > $scrollParent.height()) {
      // scrolling to available space below
      $scrollParent.scrollTop(
        $targetOffset + $scrollParent.scrollTop() - $scrollParent.height() + $target.outerHeight()
      );
    }
  }

  toggleSelection($option) {
    const isSelected = $option[0].dataset.selected === 'true';
    const index = Number($option[0].dataset.index);
    if (this.isMultiselectEnabled) {
      this.setSelectionByIndex(index, !isSelected);
      this.updateValue();
      return;
    }
    if (this.isDeselectEnabled && isSelected) {
      this.setSelectionByIndex(index, false);
      this.closeDrawer();
    } else if (!isSelected) {
      this.setSelectionByIndex(this.selectedIndex, false);
      this.setSelectionByIndex(index, true);
      this.closeDrawer();
    }
  }

  suggestOption(key) {
    window.clearTimeout(this.suggestionTimeoutHandle);
    this.suggestionTimeoutHandle = window.setTimeout(() => {
      this.suggestionString = '';
    }, 800);

    const newSuggestionString = this.suggestionString + key.toLowerCase();

    for (let i = 0; i < this.optionsArray.length; i++) {
      if (this.optionsArray[i].text.toLowerCase().startsWith(newSuggestionString)) {
        this.scrollToKeepHighlightedVisible(i);
        this.suggestionString = newSuggestionString;
        return;
      }
    }
  }

  changeHighlightedIndexBy(startingIndex, delta) {
    const optionsLength = this.drawerOptionTargets.length;
    this.wasMousemoved = false;
    let index = startingIndex;
    let lastValidIndex = startingIndex;

    let remainingDelta = Math.abs(delta);

    const offset = delta < 0 ? -1 : 1;

    while (remainingDelta) {
      for (index; index < optionsLength && index >= 0; ) {
        index += offset;
        if (index < 0 || index >= this.optionsArray.length || this.optionsArray[index].visible) {
          break;
        }
      }

      if (index === 0 || index === optionsLength) {
        index = lastValidIndex;
      } else {
        lastValidIndex = index;
      }
      if (index > 0 && index < optionsLength && index !== startingIndex) {
        this.scrollToKeepHighlightedVisible(index);
      }
      remainingDelta--;
    }
  }

  dispatchChangeEvent() {
    $(this.selectElementTarget)[0].dispatchEvent(new Event('change'));
  }

  openDrawer() {
    if (this.isEmpty) {
      return;
    }

    this.isActive = true;

    this.updateValue();
    this.showAllOptions();

    if (this.isSearchEnabled && !this.isIE11) {
      this.searchFieldTarget.focus();
    }

    // Used to keep track of changes between openDrawer & closeDrawer
    this.selectedValue = String($(this.selectElementTarget).val());
  }

  showAllOptions() {
    const $options = $(this.drawerOptionTargets);
    $options.show();
    this.optionsArray.forEach(option => {
      // eslint-disable-next-line no-param-reassign
      option.visible = true;
    });

    const $selectedTargets = $(this.drawerOptionTargets).filter('[data-selected=true]');
    $selectedTargets.length
      ? this.scrollToKeepHighlightedVisible(Number($selectedTargets[0].dataset.index))
      : this.scrollToKeepHighlightedVisible(1);
  }

  closeDrawer() {
    // when results are set using a searchUrl we don't reset the options upon re-opening, the
    // searchField should reflect that by showing the search term that filtered the options
    if (this.isSearchEnabled && !this.searchUrl) {
      this.searchFieldTarget.value = '';
    }
    this.isActive = false;

    if (this.selectedValue !== String($(this.selectElementTarget).val())) {
      this.dispatchChangeEvent();
    } else {
      this.updateValue();
    }
  }

  scrollbarClicked = mouseX => $(window).outerWidth() - 15 < mouseX;

  handleKeydown(event) {
    const { keyCode } = event;
    if (this.isEmpty || this.isReadOnly) {
      if (keyCode === keyCodes.escape || keyCode === keyCodes.tab) {
        this.setOverflow(false);
      }
      return;
    }
    const $highlightedOption = $(this.drawerOptionTargets).filter('.c-select__option--highlighted');

    switch (keyCode) {
      case keyCodes.escape:
        // Stop propagation to prevent a parent modal from capturing the escape keypress.
        if ($(this.containerTarget).hasClass('c-input--active')) {
          event.stopPropagation();
        }
        if (this.isSearchEnabled && this.searchFieldTarget.value !== '') {
          this.searchFieldTarget.value = '';
          this.handleInput();
        } else {
          this.closeDrawer();
        }
        break;
      case keyCodes.enter:
        if (this.noselect) {
          this.closeDrawer();
          return;
        }
        this.toggleSelection($highlightedOption);
        break;
      case keyCodes.home:
        event.preventDefault();
        this.wasMousemoved = false;
        this.scrollToKeepHighlightedVisible(1);
        // this is to prevent the user from highlighting options that
        // are not visible due to filtering
        if (!this.optionsArray[1].visible) {
          this.changeHighlightedIndexBy(0, 1);
        }
        break;
      case keyCodes.end:
        event.preventDefault();
        this.wasMousemoved = false;
        this.scrollToKeepHighlightedVisible(-1);
        // this is to prevent the user from highlighting options that
        // are not visible due to filtering

        if (!this.optionsArray[this.optionsArray.length - 1].visible) {
          this.changeHighlightedIndexBy(this.optionsArray.length - 1, -1);
        }
        break;
      case keyCodes.spacebar:
        if (this.isSearchEnabled && $(this.searchFieldTarget).val() === '') event.preventDefault();
        break;
      case keyCodes.tab:
        if (this.isActive && !this.isIE11) {
          this.element.focus();
        }
        this.closeDrawer();
        break;
      case keyCodes.up:
        event.preventDefault();
        this.changeHighlightedIndexBy(this.highlightedIndex, -1);
        break;
      case keyCodes.pageUp:
        event.preventDefault();
        this.changeHighlightedIndexBy(this.highlightedIndex, -4);
        break;
      case keyCodes.pageDown:
        event.preventDefault();
        this.changeHighlightedIndexBy(this.highlightedIndex, 4);
        break;
      case keyCodes.down:
        event.preventDefault();
        this.changeHighlightedIndexBy(this.highlightedIndex, 1);
        break;
      case keyCodes.shift:
        // if the searchField is going to be focused by default the
        // shift key should be ignored because it causes the element to be
        // re-focused when shift-tabbing. Of course only a problem in IE11.
        break;
      default:
        if (this.isSearchEnabled && !this.isReadOnly) {
          this.searchFieldTarget.focus();
        } else {
          this.suggestOption(event.key);
        }
    }
  }

  handleOptionMutation() {
    if (this.isReadOnly) {
      return;
    }
    this.initialize();
    // the next two lines exist because if the options are mutated while the drawer is open
    // then it will enter a state where the highlighted index is -1 and breaks usability
    this.highlightedIndex = 1;
    this.scrollToKeepHighlightedVisible(1);
  }

  handleSelectRefresh() {
    this.initialize();
  }

  handleSelectChange() {
    const selectVal = $(this.selectElementTarget).val();

    if (!this.isMultiselectEnabled) {
      this.setSelectionByIndex(this.selectedIndex, false);
    }

    this.optionsArray.forEach((option, index) => {
      const optionValue = option.value;

      const shouldBeSelected =
        Array.isArray(selectVal) && selectVal.includes(optionValue)
          ? selectVal.find(str => str === optionValue)
          : selectVal === optionValue;

      if (option.selected !== shouldBeSelected) {
        this.setSelectionByIndex(index, shouldBeSelected);
      }
    });

    this.updateValue();
  }

  handleFocus() {
    if (this.isReadOnly) {
      this.setOverflow(true);
      return;
    }
    if (!this.isActive) {
      this.openDrawer();
    }
  }

  handleMousedownOutside = event => {
    if (this.isReadOnly) {
      this.setOverflow(false);
      return;
    }
    const clickedOutside = !this.element.contains(event.target);

    if (this.isIE11) {
      // IE11 occasionally will check if the click was outside after deleting a pill
      // even though that method has an event.stopPropagation()
      // after the pill element is deleted that changes the event.target to this generic
      // element instance
      if (event.target instanceof SVGElementInstance) {
        return;
      }
    }

    if (this.isEdge) {
      // Edge occasionally will check if the click was outside after deleting a pill
      // even though that method has an event.stopPropagation()
      // after the pill element is deleted that changes the event.target to this generic
      // element instance
      if (event.target instanceof SVGPolygonElement) {
        return;
      }
    }

    if (this.scrollbarClicked(event.clientX)) {
      return;
    }

    if (clickedOutside) {
      this.closeDrawer();
    }
  };

  handleClick() {
    if (this.isReadOnly) {
      this.setOverflow(true);
      return;
    }
    if (this.isIE11) {
      this.element.focus();
    }

    if (!this.isActive) {
      this.openDrawer();
    }
  }

  handleClickMask(event) {
    event.stopPropagation();
    if (this.isReadOnly) {
      this.setOverflow(false);
      return;
    }
    this.closeDrawer();
  }

  handleClickPillEx(event) {
    event.stopPropagation();

    const index = Number($(event.currentTarget).attr('data-index'));

    this.setSelectionByIndex(index, false);
    this.updateValue();
  }

  handleInput() {
    if (!this.isSearchEnabled) {
      return;
    }

    const searchValue = this.searchFieldTarget.value;

    if (this.searchUrl) {
      if (searchValue === this.previousSearch) {
        return;
      }

      this.previousSearch = searchValue;

      const url = this.searchUrl + (this.searchUrl.indexOf('?') === -1 ? '?' : '&');

      $.get(`${url}term=${searchValue.replace('&', '%26')}`, response => {
        if (response.term && response.term !== this.searchFieldTarget.value) {
          return;
        }
        let options = '';

        // add options with the selected attribute to the new set of options before removing
        // this is important because if the user's search term doesn't match the selected
        // option(s) then they will appear to have become de-selected by searching
        $(this.optionElementTargets).each((index, optionElement) => {
          if (optionElement.selected) {
            options += `<option data-target="select.optionElement" value="${optionElement.value}" selected>${optionElement.textContent}</option>`;
          }
        });

        $(this.optionElementTargets).empty();

        response.items.forEach(item => {
          if (!options.includes(`value="${item.id}"`)) {
            options += `<option data-target="select.optionElement" value="${item.id}">${item.name}</option>`;
          }
        });

        if (response.items.length === 0) {
          options += '<option data-target="select.optionElement" disabled>No Results</option>';
        }

        $(this.selectElementTarget).append(options);
      });
    } else {
      this.foundFirstMatch = false;

      const addingToSearchValue =
        searchValue.startsWith(this.previousSearch) || searchValue.endsWith(this.previousSearch);

      const showIds = [];
      const hideIds = [];

      this.optionsArray.forEach((option, i) => {
        if (addingToSearchValue) {
          // options that are already hidden won't match
          if (!option.visible) return;
        }
        const foundMatch = doAnyWordsBeginWith(option.text, searchValue);
        if (foundMatch) {
          showIds.push(`#${option.id}`);
          // eslint-disable-next-line no-param-reassign
          option.visible = true;
        } else {
          hideIds.push(`#${option.id}`);
          // eslint-disable-next-line no-param-reassign
          option.visible = false;
        }
        if (foundMatch && !this.foundFirstMatch) {
          this.scrollToKeepHighlightedVisible(i);
          this.foundFirstMatch = true;
        }
      });

      $(showIds.join(',')).show();
      $(hideIds.join(',')).hide();

      this.previousSearch = searchValue;
    }
  }

  handleMouseenterOption(event) {
    if (!this.isSomethingHighlighted || this.wasMousemoved === true) {
      const index = Number(event.target.dataset.index);
      this.setHighlightByIndex(index, true);
      this.highlightedIndex = index;
      this.wasMousemoved = false;
    }
  }

  handleMouseupOption(event) {
    if (this.noselect) {
      this.closeDrawer();
    } else {
      event.stopPropagation();
      this.toggleSelection($(this.drawerOptionTargets[event.target.dataset.index]));
    }
  }

  handleMousemove() {
    this.wasMousemoved = true;
  }

  setHighlightByIndex(index, shouldHighlight) {
    if (index < 0 || index >= this.optionsArray.length) {
      return;
    }
    if (shouldHighlight) {
      this.setHighlightByIndex(this.highlightedIndex, false);
      $(this.drawerOptionTargets[index]).addClass('c-select__option--highlighted');
      this.optionsArray[index].highlighted = true;
      this.highlightedIndex = Number(index);
      this.isSomethingHighlighted = true;
    } else {
      $(this.drawerOptionTargets[index]).removeClass('c-select__option--highlighted');
      this.optionsArray[index].highlighted = false;
      this.highlightedIndex = Number(-1);
      this.isSomethingHighlighted = false;
    }
  }

  setSelectionByIndex(index, shouldSelect) {
    if (index < 0) {
      return;
    }
    const $drawerOption = $(this.drawerOptionTargets[index]);
    const optionElement = this.optionElementTargets[index];
    if (shouldSelect) {
      $drawerOption.addClass('c-select__option--selected');
      optionElement.selected = true;
      this.optionsArray[index].selected = true;
      $drawerOption.attr('data-selected', 'true');
      $drawerOption.attr('aria-selected', 'true');
      this.selectedIndex = index;
    } else {
      $drawerOption.removeClass('c-select__option--selected');
      optionElement.selected = null;
      this.optionsArray[index].selected = false;
      $drawerOption.attr('data-selected', 'false');
      $drawerOption.attr('aria-selected', 'false');
    }
  }

  get isActive() {
    return this.data.get('active') === 'true';
  }

  set isActive(shouldSetActive) {
    $(this.containerTarget).toggleClass('c-input--active c-input--overflow', shouldSetActive);
    if (this.labelText && this.isEmployee) {
      $(this.labelMaskTarget).toggleClass('c-select__label-mask--active', shouldSetActive);
    }
    this.setOverflow(shouldSetActive);
    $(this.drawerOptionsTarget).toggleClass('c-select__options--overflow', shouldSetActive);
    $(this.element).attr('aria-expanded', `${shouldSetActive}`);

    this.data.set('active', shouldSetActive);
  }

  setOverflow(shouldSetOverflow) {
    if (shouldSetOverflow) {
      document.addEventListener('mousedown', this.handleMousedownOutside, true);
    } else {
      document.removeEventListener('mousedown', this.handleMousedownOutside, true);
    }
    $(this.containerTarget).toggleClass('c-input--overflow', shouldSetOverflow);
  }
}

// switch_to_controller.js
import { Controller } from 'stimulus';
import $ from 'jquery';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import { keyCodes } from '../lib/global';

export default class extends Controller {
  static targets = ['dropdown', 'search', 'list', 'option', 'prev', 'next'];

  initialize() {
    this.activeIndex = this.selectedIndex();
    $(this.element).attr('tabindex', 0);
    if (document.activeElement === document.querySelector('body')) {
      $(this.element).trigger('focus');
    }
    this.route = $('body').attr('data-route');
    this.searchUrl = $(this.element).attr('data-search-url');
    this.navigationUrl = $(this.element).attr('data-navigation-url');
    this.fetchLimit = 50;
    this.stashedOptions = '';
    this.activeOption = this.optionTargets[this.activeIndex];
    this.getPrev = throttle(this.getPrev, 1000, { trailing: true });
    this.getNext = throttle(this.getNext, 1000, { trailing: true });
    this.search = debounce(this.search, 300);
    this.hiddenIds = {};
    this.updateButtons();
    this.customOptionOnclick = $(this.element).attr('data-option-onclick');
  }

  close() {
    $(this.dropdownTarget).removeClass('open');
    document.removeEventListener('mousedown', this.handleMousedown, true);
  }

  open() {
    if ($(this.dropdownTarget).hasClass('do-not-open')) return;
    if ($(this.dropdownTarget).hasClass('open')) return;
    $(this.dropdownTarget).addClass('open');
    document.addEventListener('mousedown', this.handleMousedown, true);
    // these two scroll events ensure that the active option will end up in the middle of the dropdown
    this.scrollToKeepHighlightedVisible(
      Math.min(this.activeIndex + 2, this.optionTargets.length - 1)
    );
    this.scrollToKeepHighlightedVisible(this.activeIndex);
  }

  handleMousedown = event => {
    const isClickInside = this.element.contains(event.target);
    if (!isClickInside) {
      this.close();
    }
  };

  updateButtons() {
    if (this.activeIndex > 0) {
      $(this.prevTarget).attr('disabled', false);
    }
    if (this.activeIndex < this.optionTargets.length - 1) {
      $(this.nextTarget).attr('disabled', false);
    }
  }

  onkeyup(event) {
    const { keyCode } = event;

    switch (keyCode) {
      case keyCodes.escape:
      case keyCodes.enter:
      case keyCodes.home:
      case keyCodes.end:
      case keyCodes.spacebar:
      case keyCodes.tab:
      case keyCodes.up:
      case keyCodes.pageUp:
      case keyCodes.pageDown:
      case keyCodes.down:
      case keyCodes.left:
      case keyCodes.right:
        return;
      default:
        this.search();
    }
  }

  search() {
    if (!$(this.dropdownTarget).hasClass('open')) {
      this.open();
    }
    if (this.searchUrl) {
      // results for this component are retrieved asynchronously
      const searchValue = $(this.searchTarget)
        .val()
        .toLowerCase();
      if (searchValue.length > 1) {
        if (!$(this.dropdownTarget).hasClass('open')) {
          this.open();
        }
        $.get(`${this.searchUrl}&term=${searchValue}&route=${this.route}&limit=100`, response => {
          this.replaceOptions(response);
        });
      }
      if (searchValue.length === 0 && this.stashedOptions !== '') {
        if (!$(this.dropdownTarget).hasClass('open')) {
          this.open();
        }
        this.popStashedOptions();
      }
    } else {
      const searchValue = $(this.searchTarget)
        .val()
        .toLowerCase();
      const showIds = [];
      const hideIds = [];
      $(this.optionTargets).each((_, option) => {
        const text = $(option)
          .text()
          .toLowerCase();
        const foundMatch =
          text === '' || text.startsWith(searchValue) || text.indexOf(` ${searchValue}`) > -1;
        const guid = $(option).attr('guid');

        const visible = this.hiddenIds[guid] !== true;
        if (visible === foundMatch) return;

        if (foundMatch) {
          showIds.push(`[guid=${guid}]`);
          this.hiddenIds[guid] = false;
        } else {
          hideIds.push(`[guid=${guid}]`);
          this.hiddenIds[guid] = true;
        }
      });
      $(showIds.join(',')).show();
      $(hideIds.join(',')).hide();
    }

    if (this.highlightedIndex < this.optionTargets.length - 1) {
      this.changeHighlightedIndexBy(this.highlightedIndex, 1);
      this.changeHighlightedIndexBy(this.highlightedIndex, -1);
    }
  }

  replaceOptions(data) {
    if (
      data.items.length > 0 &&
      data.term ===
        $(this.searchTarget)
          .val()
          .toLowerCase()
    ) {
      // proceed if this data matches current search input.  Mismatches would happen if responses come back out of order.
      if (!this.stashedOptions) {
        this.stashedOptions = $('.c-switch-to__list-item');
      }
      $(this.listTarget).empty();

      const { items } = data;
      items.forEach((item, i) => {
        const index = i;
        const profileUrl = items[i].url;
        const listItem = `<li data-target="switchto.option" data-index="${index}" onclick="window.location.href='${profileUrl}';" class="c-switch-to__list-item" data-action="mousemove->switchto#handleMousemove mouseenter->switchto#handleMouseenterOption">${items[i].name}</li>`;
        $(this.listTarget).append(listItem);
      });
      this.scrollToKeepHighlightedVisible(0);
    }
  }

  popStashedOptions() {
    $(this.listTarget).empty();
    $(this.listTarget).append(this.stashedOptions);
    this.stashedOptions = '';
  }

  handleScroll() {
    if (this.navigationUrl) {
      const $firstOption = $(this.optionTargets[0]);
      const $lastOption = $(this.optionTargets[this.optionTargets.length - 1]);
      const $scrollParent = $(this.dropdownTarget);
      if ($scrollParent.offset() === 'undefined') {
        return;
      }
      const $scrollParentBottom = $scrollParent.offset().top + $scrollParent.height();

      const distanceToBottom =
        $lastOption.offset().top + $lastOption.outerHeight() - $scrollParentBottom;
      const distanceToTop = $scrollParent.offset().top - $firstOption.offset().top;

      if (distanceToTop === 0) {
        this.getPrev();
      }

      if (distanceToBottom < 5 * $(this.optionTarget).outerHeight()) {
        this.getNext();
      }
    }
  }

  getPrev() {
    if ($(this.searchTarget).val() !== '') {
      return; // search results return a finite set of matches, do not fetch more.
    }
    const $firstOption = $(this.optionTargets[0]);
    const employeeId = $firstOption.attr('employee-id');
    $.get(
      `${this.navigationUrl}&id=${employeeId}&direction=prev&route=${this.route}&limit=${this.fetchLimit}`,
      response => {
        const { items } = response;
        const shouldPrepend =
          items.length > 0 && response.id === $(this.optionTargets[0]).attr('employee-id');
        if (shouldPrepend) {
          items.forEach(item => {
            const index = -1;
            const oldScrollTop = $(this.listTarget)
              .scrollParent()
              .scrollTop();
            const listItem = this.createListOptionString(index, item);
            $(this.listTarget).prepend(listItem);
            $(this.listTarget)
              .scrollParent()
              .scrollTop(oldScrollTop + $(this.optionTarget).outerHeight());
          });
          // re-index the options, perhaps a cleaner option might be to allow negative indices
          this.optionTargets.forEach((option, i) => {
            $(option).attr('data-index', i);
          });
        }
      }
    );
  }

  getNext() {
    if ($(this.searchTarget).val() !== '') {
      return; // search results return all matches, do not fetch more.
    }
    const $lastOption = $(this.optionTargets[this.optionTargets.length - 1]);
    const employeeId = $lastOption.attr('employee-id');
    $.get(
      `${this.navigationUrl}&id=${employeeId}&direction=next&route=${this.route}&limit=${this.fetchLimit}`,
      response => {
        const { items } = response;
        const shouldAppend =
          items.length > 0 &&
          response.id === $(this.optionTargets[this.optionTargets.length - 1]).attr('employee-id');
        if (shouldAppend) {
          const lastIndex = $lastOption.attr('data-index');
          items.forEach((item, i) => {
            const index = Number(lastIndex) + i + 1;
            const listItem = this.createListOptionString(index, item);
            $(this.listTarget).append(listItem);
          });
        }
      }
    );
  }

  createListOptionString(index, item) {
    if (this.customOptionOnclick) {
      const onclickString = this.parseCustomOption(item);
      return `<li data-target="switchto.option" data-index="${index}" employee-id="${item.id}" onclick="${onclickString};" class="c-switch-to__list-item" data-action="mousemove->switchto#handleMousemove mouseenter->switchto#handleMouseenterOption">${item.name}</li>`;
    }
    return `<li data-target="switchto.option" data-index="${index}" employee-id="${item.id}" onclick="window.location.href='${item.url}';" class="c-switch-to__list-item" data-action="mousemove->switchto#handleMousemove mouseenter->switchto#handleMouseenterOption">${item.name}</li>`;
  }

  parseCustomOption(item) {
    const regex = /<(.*?)>/g;
    const customOptions = this.customOptionOnclick.match(regex);
    let parsedOnclick = this.customOptionOnclick;
    customOptions.forEach(customOption => {
      const key = customOption.slice(1, -1);
      const value = item[key];
      parsedOnclick = parsedOnclick.replace(customOption, value);
    });
    return parsedOnclick;
  }

  blur(event) {
    event.stopPropagation();
    $(this.dropdownTarget).removeClass('open');
  }

  previous() {
    const index = this.activeIndex;
    if (index > 0) {
      $(this.dropdownTarget).addClass('do-not-open');
      $(this.optionTargets[index - 1]).click();
      $(this.dropdownTarget).removeClass('do-not-open');
    }
  }

  next() {
    const index = this.activeIndex;
    if (index > -1 && index < this.optionTargets.length - 1) {
      $(this.dropdownTarget).addClass('do-not-open');
      $(this.optionTargets[index + 1]).click();
      $(this.dropdownTarget).removeClass('do-not-open');
    }
  }

  selectedIndex() {
    let selected = -1;
    this.optionTargets.forEach((option, i) => {
      if ($(option).attr('data-selected')) {
        selected = i;
      }
    });
    return selected;
  }

  changeHighlightedIndexBy(index, delta) {
    const optionsLength = this.optionTargets.length;
    this.wasMousemoved = false;
    let targetIndex = index;
    let lastValidIndex = index;

    const d = delta < 0 ? -1 : 1;
    let i = Math.abs(delta);

    for (i; i > 0 && targetIndex < optionsLength && targetIndex >= 0; ) {
      targetIndex += d;
      if ($(this.optionTargets[targetIndex]).is(':visible')) {
        lastValidIndex = targetIndex;
        i--;
      }
    }

    if (targetIndex < optionsLength && targetIndex >= 0) {
      this.scrollToKeepHighlightedVisible(targetIndex);
    } else if (targetIndex < 0) {
      this.scrollToKeepHighlightedVisible(lastValidIndex);
    }
  }

  scrollToKeepHighlightedVisible(index) {
    const $target = $(this.optionTargets).eq(index);
    this.setHighlightByIndex(index, true);

    const $scrollParent = $target.scrollParent();
    const $targetOffset = $target.offset().top - $scrollParent.offset().top;

    if ($targetOffset < 0) {
      // scrolling to available space above
      $scrollParent.scrollTop($targetOffset + $scrollParent.scrollTop());
    } else if ($targetOffset + $target.outerHeight() > $scrollParent.height()) {
      // scrolling to available space below
      $scrollParent.scrollTop(
        $targetOffset + $scrollParent.scrollTop() - $scrollParent.height() + $target.outerHeight()
      );
    }
  }

  toggleSelection(index) {
    this.optionTargets[index].click();
  }

  setHighlightByIndex(index, shouldHighlight) {
    if (index < 0 || index >= this.optionTargets.length) {
      return;
    }
    if (shouldHighlight) {
      this.setHighlightByIndex(this.highlightedIndex, false);
      $(this.optionTargets[index]).addClass('c-switch-to__list-item--highlighted');
      this.highlightedIndex = Number(index);
      this.isSomethingHighlighted = true;
    } else {
      $(this.optionTargets[index]).removeClass('c-switch-to__list-item--highlighted');
      this.highlightedIndex = Number(-1);
      this.isSomethingHighlighted = false;
    }
  }

  shouldPassFocusToSearchInput(key) {
    switch (key) {
      case ' ': // Space
      case 'Alt':
      case 'AltGraph':
      case 'Apps': // IE reports 'Apps' instead of 'ContextMenu'
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ArrowUp':
      case 'Backspace':
      case 'CapsLock':
      case 'Clear':
      case 'ContextMenu':
      case 'Control':
      case 'Del': // IE reports 'Del' instead of 'Delete'
      case 'Delete':
      case 'Down': // IE reports 'Down' instead of 'ArrowDown'
      case 'End':
      case 'Enter':
      case 'Esc': // IE reports 'Esc' instead of 'Escape'
      case 'Escape':
      case 'F1':
      case 'F2':
      case 'F3':
      case 'F4':
      case 'F5':
      case 'F6':
      case 'F7':
      case 'F8':
      case 'F9':
      case 'F10':
      case 'F11':
      case 'F12':
      case 'Fn':
      case 'FnLock':
      case 'Home':
      case 'Insert':
      case 'Left':
      case 'Meta':
      case 'PageDown':
      case 'PageUp':
      case 'Right':
      case 'Scroll': // IE reports 'Scroll' instead of 'ScrollLock'
      case 'ScrollLock':
      case 'Shift':
      case 'Spacebar': // IE reports 'Spacebar' instead of ' '
      case 'Up': // IE reports 'Up' instead of 'ArrowUp'
        return false;
      default:
        return true;
    }
  }

  handleMouseenterOption(event) {
    if (!this.isSomethingHighlighted || this.wasMousemoved === true) {
      const index = Number(event.target.dataset.index);
      this.setHighlightByIndex(index, true);
      this.highlightedIndex = index;
      this.wasMousemoved = false;
    }
  }

  handleMousemove() {
    this.wasMousemoved = true;
  }

  handleKeydown(event) {
    const { keyCode } = event;

    // this.element (the search input's container) has focus when the page loads until the user focuses another element on the page
    if ($(this.element).is(':focus')) {
      if (this.shouldPassFocusToSearchInput(event.key)) {
        $(this.searchTarget).trigger('focus');
      } else {
        // respond to these key events without passing focus to the search input
        switch (event.key) {
          case 'ArrowRight':
          case 'Right':
            if (this.searchTarget.value === '') {
              event.preventDefault();
              this.next();
            }
            break;
          case 'ArrowLeft':
          case 'Left':
            if (this.searchTarget.value === '') {
              event.preventDefault();
              this.previous();
            }
            break;
          default:
            break;
        }
      }
    }

    // the rest of the code in this block should run only when the search input has focus
    if (!$(this.searchTarget).is(':focus')) return;

    switch (keyCode) {
      case keyCodes.escape:
        if (this.searchTarget.value !== '') {
          this.searchTarget.value = '';
        } else {
          this.close();
        }
        break;
      case keyCodes.enter:
        if (typeof this.highlightedIndex !== 'undefined') {
          this.toggleSelection(this.highlightedIndex);
        }
        break;
      case keyCodes.home:
        event.preventDefault();
        this.wasMousemoved = false;
        this.scrollToKeepHighlightedVisible(0);
        break;
      case keyCodes.end:
        event.preventDefault();
        this.wasMousemoved = false;
        this.scrollToKeepHighlightedVisible(this.optionTargets.length - 1);
        break;
      case keyCodes.spacebar:
        if ($(this.searchTarget).val() === '') event.preventDefault();
        break;
      case keyCodes.tab:
        this.close();
        break;
      case keyCodes.up:
        event.preventDefault();
        this.changeHighlightedIndexBy(this.highlightedIndex, -1);
        break;
      case keyCodes.pageUp:
        event.preventDefault();
        this.changeHighlightedIndexBy(this.highlightedIndex, -4);
        break;
      case keyCodes.pageDown:
        event.preventDefault();
        this.changeHighlightedIndexBy(this.highlightedIndex, 4);
        break;
      case keyCodes.down:
        event.preventDefault();
        if ($(this.dropdownTarget).hasClass('open')) {
          this.changeHighlightedIndexBy(this.highlightedIndex, 1);
        } else {
          this.open();
        }
        break;
      default:
        break;
    }
  }
}

import { Controller } from 'stimulus';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';

export default class extends Controller {
  initialize() {
    $(this.element).sortable({
      handle: '.c-brick__handle',
      axis: 'y',
      containment: 'parent',
      tolerance:'pointer',
      update: () => {
        $(this.element).get(0).dispatchEvent(new Event('change'));
      }
    });
  }
}

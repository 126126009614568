import { Controller } from 'stimulus';
import $ from 'jquery';
import moment from 'moment';
import 'fullcalendar';
import 'jquery-ui/ui/widgets/datepicker';

export default class extends Controller {
  static targets = ['title', 'calendar', 'datepicker', 'today'];

  initialize() {
    this.constants = {
      classBar: 'c-calendar__bar',
      classContainer: 'c-calendar__container',
      classEvent: 'c-calendar__event',
      classImg: 'c-calendar__img',
      classInitials: 'c-calendar__initials',
      classCardItem: 'c-calendar-card__item',
      classCardContainer: 'col-12 mb-3',
      classCardImg: 'c-calendar-card__img',
      classCardInitials: 'c-calendar-card__initials',
      eventType: {
        holiday: 'Holiday'
      },
      statusPending: 'pending',
      statusApproved: 'approved',
      statusHoliday: 'holiday'
    };

    this.isResponsive = document.body.getAttribute('data-responsive') !== null || false;
    this.currentDates = {};
    this.createCalendars(window.easedata.events);
  }

  createCalendars = data => {
    $(this.calendarTarget).fullCalendar({
      events: this.createEvents(data),
      eventLimit: 3,
      displayEventTime: false,
      header: false,
      height: 'auto',
      eventLimitClick: this.handleEventLimitClick,
      eventRender: this.handleEventRender,
      viewRender: this.handleViewRender
    });

    if (this.isResponsive) {
      $(this.calendarTarget).addClass('c-calendar__calendar--responsive');
      $(this.datepickerTarget).datepicker({
        inline: true,
        showOtherMonths: true,
        dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        beforeShowDay: this.createPickerClass,
        onSelect: this.scrollToCard,
        onChangeMonthYear: this.syncCalendarDates
      });
    }
  };

  createEvents = events =>
    events.map(event => ({
      allDay: event.all_day,
      title: event.name || '',
      start: event.start_date,
      end: event.end_date,
      isPending: event.is_pending,
      className: this.createClass(event),
      rendering: this.createRenderType(event.type),
      image: event.image_url,
      initials: this.getInitials(event.name),
      type: event.type
    }));

  handleEventLimitClick = cellInfo => {
    const date = cellInfo.date.format('YYYY-MM-DD');
    const row = `.js-calendar-row--${date}`;
    $(row).show();

    document.addEventListener(
      'click',
      function clickOut(e) {
        if (!$(e.target).closest('.js-calendar-row').length) {
          $(row).hide();
          document.removeEventListener('click', clickOut);
        }
      },
      true
    );
  };

  handleEventRender = (event, element) => {
    if (event.type === this.constants.eventType.holiday) {
      $(`td[data-date=${event.start.format('YYYY-MM-DD')}]`).addClass('c-calendar__holiday');
      return;
    }

    const status = this.getStatus(event);
    const $container = this.createEventContainer(event, status);
    this.moveTitleToContainer(element, $container);
    this.addElementsToDom(element, $container, event.end);

    this.addElementsToDom(
      element,
      $container,
      event.end && event.end.diff(event.start, 'days') > 1
        ? this.createEventBar(event, status)
        : null
    );
  };

  handleViewRender = view => {
    $(this.titleTarget).text(view.title);
    this.setTodayBtnState();
    this.viewEvents = this.getViewEvents();
    this.destroyCards();
    this.createCards(this.viewEvents);
  };

  createPickerClass = date => {
    let className = 'c-calendar__num';
    const currentCalDate = moment(date);
    const fullCalDate = moment($(this.calendarTarget).fullCalendar('getDate'));
    const today = moment().format('YYYY-MM-DD');
    const events = this.viewEvents;

    const dateHasEvent = events.find(event => {
      if (event.end) {
        return currentCalDate.isBetween(event.start, event.end, null, '[]');
      }
      return currentCalDate.isSame(event.start.format('YYYY-MM-DD'));
    });

    const isAHoliday = events.find(
      event =>
        event.type === this.constants.eventType.holiday &&
        currentCalDate.isSame(event.start.format('YYYY-MM-DD'))
    );

    if (dateHasEvent) {
      className += ' c-calendar__num--event';
    }

    if (currentCalDate.isSame(today)) {
      className += ' c-calendar__num--today';
    }

    if (!currentCalDate.isSame(fullCalDate, 'month')) {
      className += ' c-calendar__num--disabled';
    }

    if (isAHoliday) {
      className += ' c-calendar__num--holiday';
    }

    return dateHasEvent ? [true, className] : [false, className];
  };

  scrollToCard = date => {
    const formattedDate = moment(new Date(date)).format('YYYY-MM-DD');
    const scrollTop = $(`.js-calendar-card--${formattedDate}`).offset().top;

    $('html, body').animate({ scrollTop }, this.setAnimateTime());
  };

  syncCalendarDates = (year, month, obj) => {
    const newDate = moment()
      .month(obj.selectedMonth)
      .year(obj.selectedYear);
    $(this.calendarTarget).fullCalendar('gotoDate', newDate);
  };

  moveTitleToContainer = (element, container) =>
    $(element)
      .find('.fc-title')
      .detach()
      .appendTo(container);

  addElementsToDom = (element, container, bar) =>
    element
      .find('.fc-content')
      .prepend(container)
      .append(bar);

  createEventBar = (event, status) =>
    $('<div />', {
      class: this.createChildClass(this.constants.classBar, status)
    });

  createClass = event => {
    const status = this.getStatus(event, true);
    let className = this.createChildClass(this.constants.classEvent, status);

    if (event.type === this.constants.eventType.holiday) {
      className += ` ${this.constants.classEvent}--${this.constants.statusHoliday}`;
    }

    return className;
  };

  createChildClass = (elementClass, status) => `${elementClass} ${elementClass}--${status}`;

  createRenderType = type => (type === this.constants.eventType.holiday ? 'background' : '');

  createEventContainer = (event, status) => {
    const container = $('<div />', {
      class: this.createChildClass(this.constants.classContainer, status)
    });
    const embed = this.createEmbed(event, status);

    return container.append(embed);
  };

  createCards = events => {
    if (!events.length) {
      return this.createCalendarCard(moment().format('YYYY-MM-DD'), true);
    }

    return events.forEach(event => {
      const dates = this.getEventDates(event.start, event.end);
      dates.forEach(date => {
        if (!this.currentDates[date]) {
          this.createCalendarCard(date);
          this.currentDates[date] = 1;
        }

        const attachmentMethod =
          event.type === this.constants.eventType.holiday ? 'prepend' : 'append';

        return $(`.js-calendar-card--${date}`)
          .find('.c-calendar-card__container')
          [attachmentMethod](this.createCalendarCardItem(event));
      });
    });
  };

  createEmbed = (event, status, isForCard) => {
    const imgClass = isForCard ? this.constants.classCardImg : this.constants.classImg;
    const initialsClass = isForCard
      ? this.constants.classCardInitials
      : this.constants.classInitials;

    return this.createContentEl(
      event.image ? 'img' : 'div',
      event.image ? imgClass : initialsClass,
      status,
      event.image ? event.image : event.initials,
      event.image ? 'src' : 'text'
    );
  };

  createCalendarCard = (date, hasNoEvents) => {
    const row = $('<div />', {
      class: `row c-calendar_row js-calendar-row js-calendar-row--${date}`
    });
    const container = $('<div />', {
      class: `${this.constants.classCardContainer}`
    });
    const calendarCard = $('<div />', {
      class: `c-calendar-card js-calendar-card--${date}`
    });
    const header = $('<div />', { class: 'c-calendar-card__header' });
    const text = hasNoEvents ? 'No Events' : moment(date).format('dddd, MMMM D');
    const title = $('<span />', { text });
    const cardItemContaienr = $('<div />', { class: 'c-calendar-card__container' });

    calendarCard.append(header.append(title)).append(cardItemContaienr);
    container.append(calendarCard);
    row.append(container);

    $('.c-calendar').append(row);
  };

  createCalendarCardItem = event => {
    const status = this.getStatus(event);
    const container = this.createContainerEl(this.constants.classCardItem, status);
    const eventContainer = this.createContainerEl('c-calendar-card__event', status);
    const title = this.createContentEl(
      'span',
      'c-calendar-card__title',
      status,
      event.title,
      'text'
    );

    if (status === 'holiday') {
      return container.append(eventContainer.append(title));
    }

    const infoContainer = this.createContainerEl('c-calendar-card__info', status);
    const embed = this.createEmbed(event, status, true);
    const dates = this.createContentEl(
      'span',
      'c-calendar-card__dates',
      status,
      this.createDateRange(event.start, event.end),
      'text'
    );

    eventContainer.append(embed).append(title);
    infoContainer.append(dates);

    return container.append(eventContainer).append(infoContainer);
  };

  createContentEl = (el, className, status, content, contentType) =>
    $(`<${el} />`, {
      class: this.createChildClass(className, status),
      [contentType]: content
    });

  createContainerEl = (className, status) =>
    $('<div />', {
      class: this.createChildClass(className, status)
    });

  createDateRange = (start, end) => {
    if (!end) {
      return start.format('MMM. D, YYYY');
    }

    const cloneDate = moment(end)
      .clone()
      .subtract(1, 'days');

    return `${start.format('MMM. D, YYYY')} - ${cloneDate.format('MMM. D, YYYY')}`;
  };

  destroyCards = () => {
    $('.js-calendar-row').remove();
    this.currentDates = {};
  };

  getEventDates = (start, end) => {
    const dates = [];
    const currentDate = moment(start).clone();
    if (!end) {
      return [currentDate.format('YYYY-MM-DD')];
    }

    while (currentDate <= end) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'days');
    }
    return dates;
  };

  getInitials = fullName => {
    const name = fullName.split(' ');
    let initials = name[0].substring(0, 1).toUpperCase();

    if (name.length > 1) {
      initials += name[name.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  };

  getStatus = (event, creatingInitialEvents) => {
    // API call returns is_pending. This is reassigned to camel case when creating event map
    const key = creatingInitialEvents ? 'is_pending' : 'isPending';

    if (event.type === this.constants.eventType.holiday) {
      return 'holiday';
    }

    return event[key] ? this.constants.statusPending : this.constants.statusApproved;
  };

  getViewEvents = () =>
    $(this.calendarTarget)
      .fullCalendar('clientEvents', this.viewEventsFilter())
      .sort((a, b) => a.start - b.start);

  viewEventsFilter = () => {
    const view = $(this.calendarTarget).fullCalendar('getView');
    const start = view.intervalStart;
    const end = view.intervalEnd;
    return event => event.start.isBetween(start, end, null, '[]');
  };

  next() {
    $(this.calendarTarget).fullCalendar('next');
    this.updatePickerDate();
  }

  previous() {
    $(this.calendarTarget).fullCalendar('prev');
    this.updatePickerDate();
  }

  today() {
    $(this.calendarTarget).fullCalendar('today');
    this.updatePickerDate();
  }

  setAnimateTime = () => {
    const height = $('.o-wrapper__content').height();
    const msPerHeight = 1;
    const minRange = 500;
    const maxRange = 1500;
    let time = height * msPerHeight;

    time = Math.min(time, maxRange);
    time = Math.max(time, minRange);
    return time;
  };

  setTodayBtnState = () => {
    const calDate = moment($(this.calendarTarget).fullCalendar('getDate'));
    let classChangeMethod;
    let disabledState;

    if (calDate.isSame(moment().format('YYYY-MM-DD'), 'month')) {
      classChangeMethod = 'removeClass';
      disabledState = true;
    } else {
      classChangeMethod = 'addClass';
      disabledState = false;
    }

    $(this.todayTarget)
      [classChangeMethod]('is-active')
      .attr('disabled', disabledState);
  };

  updatePickerDate = () => {
    const date = moment($(this.calendarTarget).fullCalendar('getDate')).format('YYYY-MM-DD');
    const parsedDate = $.datepicker.parseDate('yy-mm-dd', date);

    $(this.datepickerTarget).datepicker('setDate', parsedDate);
  };
}

import getCursorForNode from '../utils/getCursorForNode';

export default function iconPeople({ svg, x = 0, y = 0, height = 12, width = 12 }) {

  const container = svg
    .append('path')
    .attr('x', x)
    .attr('y', y)
    .attr('width', width)
    .attr('height', height)
    .attr('cursor', getCursorForNode)
    .attr('transform', `translate(${x}, ${y})`)
    .attr('stroke', 'white')
    .attr('fill', 'none')
    .attr('stroke-width', 1)
    .attr('stroke-linecap', 'square')
    .attr(
      'd',
      'M8.5 10.5V9.5A2 2 0 0 0 6.5 7.5H2.5A2 2 0 0 0 .5 9.5V10.5M4.5 5.5A2 2 0 0 1 4.5 1.5A2 2 0 0 1 4.5 5.5M11.5 10.5V9.5A2 2 0 0 0 10 7.5M8 1.565A2 2 0 0 1 8 5.4'
    );

    return container;
}

// quill_controller.js
import { Controller } from 'stimulus';
import $ from 'jquery';
import '../lib/window';
import Quill from 'quill';
import sanitizeHtml from 'sanitize-html';
import { keyCodes } from '../lib/global';

export default class extends Controller {
  static targets = ['drawerOption'];

  initialize() {
    // https://quilljs.com/docs/configuration
    const editorId = $(this.element)
      .children('.ql-editor')
      .attr('id');

    const toolbar = $(this.element).children('.toolbar');

    Quill.registerModule('autotext', quill => {
      this.quill = quill;
    });

    const editor = new Quill(`#${editorId}`, {
      theme: 'snow',
      modules: {
        toolbar:
          !toolbar || toolbar.length <= 0
            ? true // show default toolbar
            : {
                // show custom toolbar
                container: `#${toolbar.attr('id')}`
              },
        autotext: true
      }
    });

    editor.getModule('toolbar').container.addEventListener('mousedown', e => {
      // This is necessary for the toolbar because the below blur event was being called when
      // clicking a format button, and potentially re-display the 'Partially styled markdown'
      // message when a user goes to correct formatting (as html that was being
      // evaluated was before formatting was removed).
      e.preventDefault();
    });

    $(`#${editorId}`).on('template-select-change', () => {
      $(`#${editor.container.id} .ql-editor[contenteditable=true]`).trigger('blur');
    });

    $(`#${editor.container.id} .ql-editor[contenteditable=true]`).on('blur', function() {
      let html = $(this).html();

      const id = $(this)
        .parent()
        .attr('id')
        .replace('Editor', '');

      const $elem = $(`#${id}`);
      const isAutoSave = $elem.attr('data-autosave') !== 'disabled';
      const isAdvanced = $elem.attr('data-editortype') === 'advanced';

      const current = $elem.val();

      if (isAdvanced) {
        // Santize the HTML provided in the advanced text editor.
        let sanitizedHtml = sanitizeHtml(html, {
          allowedTags: ['div', 'ol', 'ul', 'li', 'b', 'i', 'u', 'span', 'br', 'a'], // allow these HTML tags.
          allowedAttributes: {
            a: ['href', 'title'], // Excluding 'download', 'hreflang', 'ping', 'type', 'referrerpolicy', 'rel', 'target'
            '*': ['style'] // Only allow the 'style' attribute for all allowed HTML tags.
          },
          allowedStyles: {
            '*': {
              'text-align': [/^(right|left|center|justify)$/], // allow the 'text-align' style following the regex here.
              'font-size': [/^(10|13|18|32)px$/] // allow the 'font-size' style following the regex here.
            }
          },
          selfClosing: ['br'] // br is a self-closing tag, treat it as such.
        });

        // Preserve Whitespace
        sanitizedHtml = sanitizedHtml.replace(/ {2}/g, ' &nbsp;').replace(/\p{Zs}{2}/gu, ' &nbsp;');
        const b64html = window.b64Encode(sanitizedHtml);

        if (b64html === current) {
          return;
        }

        // set the advanced text editor content as the sanitized HTML.
        editor.setHTML(sanitizedHtml);

        if (!isAutoSave) {
          $elem.val(b64html);
          return;
        }

        window.saveFieldValue(id, b64html);
      } else {
        // Check for HTML tags within Markdown Hyperlinks
        const tagList = ['b', 'i', 'u', 'em', 'strong'];
        const anyTextExceptClosingBracket = '([^\\]]*)';
        const anyTextExceptClosingParentheses = '([^\\)]*)';
        const tagRegex = '(\\<(/)?('.concat(tagList.join('|'), ')\\>)');

        // [anytext](anytext<tag>anytext)
        const hyperlinkRegexEnd = new RegExp(
          '\\['.concat(
            anyTextExceptClosingBracket,
            '\\]\\(',
            anyTextExceptClosingParentheses,
            tagRegex,
            anyTextExceptClosingParentheses,
            '\\)'
          )
        );

        const hyperlinkContainsTags = html.toLowerCase().match(hyperlinkRegexEnd) != null;

        if (hyperlinkContainsTags) {
          window.showFeedback(
            'Partial styling is not supported for Markdown hyperlinks; please remove or apply to the full link.',
            'error'
          );
          return;
        }

        // Check if Link within Markdown Hyperlink is missing http
        const doesNotContainHttp = '(?!http(s?)://)';

        const hyperlinkDoesNotContainHttpRegex = new RegExp(
          '\\['.concat(
            anyTextExceptClosingBracket,
            '\\]\\(',
            doesNotContainHttp,
            anyTextExceptClosingParentheses,
            '\\)'
          )
        );

        const hyperlinkDoesNotContainHttp =
          html.toLowerCase().match(hyperlinkDoesNotContainHttpRegex) != null;

        if (hyperlinkDoesNotContainHttp) {
          window.showFeedback('Markdown hyperlinks must start with http:// or https://!', 'error');
          return;
        }

        // Preserve Whitespace
        html = html.replace(/ {2}/g, ' &nbsp;');

        // Basic Editor Replacements
        html = html.replace(/<div><br\/?><\/div>/gi, '\n');
        html = html.replace(/<p><br\/?><\/p>/gi, '\n');
        html = html.replace(/<\/div>/gi, '\n');
        html = html.replace(/<\/p>/gi, '\n');
        html = html.replace(/<div>/gi, '');
        html = html.replace(/<br\/?>/gi, '\n');
        html = html.replace(/<\/?strong>/gi, '*');
        html = html.replace(/<\/?b>/gi, '*');
        html = html.replace(/<\/?em>/gi, '~');
        html = html.replace(/<\/?i>/gi, '~');
        html = html.replace(/<\/?u>/gi, '_');

        const text = $('<div/>')
          .html(html)
          .text();

        if (text === current) {
          return;
        }

        if (!isAutoSave) {
          $elem.val(text);
          return;
        }

        window.saveFieldValue(id, text);
      }
    });
  }

  handleMouseDown(event) {
    const text = $(event.currentTarget)
      .find('.option-text')
      .text();
    if (!text) return;

    this.quill.focus();
    const range = this.quill.getSelection();

    this.insertTextAtIndex(text, range.end);
  }

  handleKeyDown(event) {
    const { keyCode } = event;
    if (keyCode !== keyCodes.enter) return;

    event.preventDefault();

    const $highlightedOption = $(this.drawerOptionTargets).filter('.c-select__option--highlighted');
    const text = $highlightedOption.find('.option-text').text();
    if (!text) return;

    this.quill.focus();
    const range = this.quill.getSelection();

    this.insertTextAtIndex(text, range.end);
  }

  insertTextAtIndex(text, index) {
    const formattedText = `[${text.toUpperCase().replace(/\s+/g, '_')}]`;
    this.quill.insertText(index, formattedText);
  }
}

/* eslint-disable no-param-reassign */
import { Controller } from 'stimulus';
import { addDataAction } from '../lib/utils';

export default class extends Controller {
  static targets = [
    'select',
    'string',
    'state',
    'filingStatus',
    'declineWithholding',
    'nonExemptInput'
  ];

  connect() {
    if (this.hasSelectTarget && this.hasStringTarget) {
      addDataAction(this.selectTarget, 'change->w4-form#pluralize');

      this.stringTargets.forEach(string => {
        // eslint-disable-next-line no-param-reassign
        string.dataset.single = string.innerHTML;
      });

      this.pluralize();
    }

    if (this.hasDeclineWithholdingTarget) {
      addDataAction(this.declineWithholdingTarget, 'change->w4-form#updateExemptStatus');
    }

    this.updateExemptStatus();
  }

  pluralize() {
    if (!this.hasStringTarget || !this.hasSelectTarget) return;

    const plural = this.selectTarget.value === 'Married filing jointly';

    this.stringTargets.forEach(string => {
      string.innerHTML = plural ? string.dataset.plural : string.dataset.single;
    });
  }

  updateExemptStatus() {
    if (this.hasDeclineWithholdingTarget) {
      this.declineWithholding = this.declineWithholdingTarget
        .getElementsByTagName('input')
        .item(0).checked;
    }

    if (this.hasNonExemptInputTarget) {
      this.nonExemptInputTargets.forEach(nonExemptInput => {
        if (this.declineWithholding) {
          nonExemptInput.style.display = 'none';
          const input = nonExemptInput.getElementsByTagName('input');
          if (input.length > 0) {
            input.item(0).value = '';
          }
          const select = nonExemptInput.getElementsByTagName('select');
          if (select.length > 0) {
            const options = select.item(0).getElementsByTagName('option');
            options.forEach(option => {
              option.removeAttribute('selected');
            });
            select.item(0).dispatchEvent(new Event('change'));
          }
        } else {
          nonExemptInput.style.display = 'block';
        }
      });
    }
  }
}

import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['input'];

    change() {
      $(this.element).toggleClass('is-active');
    }
}

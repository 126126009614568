import { Controller } from 'stimulus';
import { addDataAction, formatAsCurrency } from '../lib/utils';

export default class extends Controller {
  static targets = ['multiplicand', 'product'];

  connect() {
    if (!this.hasMultiplicandTarget) return;
    addDataAction(this.multiplicandTarget, 'change->multiply#compute');
    this.compute();
  }

  compute() {
    if (!this.hasMultiplicandTarget || !this.hasProductTarget) return;
    const multiplier = this.multiplicandTarget.getAttribute('multiplier') || 1;
    const product =
      Number(ease.page.sanitizeDecimal(this.multiplicandTarget.value)) * Number(multiplier);
    this.productTarget.value = formatAsCurrency(product);
  }
}

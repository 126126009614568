import { Controller } from 'stimulus';
import $ from 'jquery';
import init from '../d3/org_chart';
import Person from '../d3/org_chart/models/person';
import 'isomorphic-fetch';

export default class extends Controller {
  static targets = ['reset', 'employeeFilter'];

  initialize() {
    this.getData();
    this.isFiltered = false;
    this._responseData = [];
    this._chartData = [];
  }

  /*
   * Get company directory data from ease api
   */
  getData = () => {
    fetch(`/v1/hris/directory/${this.companyId}?limit=25000`)
      .then(data => data.json())
      .then(response => {
        this._responseData = response.data;
        this._chartData = this.getFormattedChartData(response.data);
        this.renderChart(this._chartData);
      })
      // eslint-disable-next-line no-console
      .catch(error => console.log(error));
  };

  /*
   * Convert raw data to chart data
   */
  getFormattedChartData = data => {
    // return empty array if no data
    if (data.length === 0) {
      return [];
    }

    // Create people dictionary with user_id as key
    // and person from response as value
    this.people = (function(responseData, result) {
      responseData.forEach(function(person) {
        person.children = [];
        result[person.id] = person;
      });

      return result;
    })(data, {});

    // Build tree data
    // *******************
    // when filtering by department, a user may have a manager that is not in the same
    // department.  In this case, we need to check to see if the manager exists in "people"
    // before accessing the "children" property
    // *******************
    // If we are not filtering, we know that "people" contains everyone so we can safely access
    // the children property

    const peopleWithoutManagers = [];

    data.forEach(person => {
      const { manager_id: managerId } = person;

      if (
        (managerId && !this.isFiltered) ||
        (managerId && this.isFiltered && this.people[managerId])
      ) {
        this.people[managerId].children.push(person);
        this.people[managerId].hasChild = true;
      } else {
        // We also track people without managers...
        peopleWithoutManagers.push(person.id);
      }
    });

    // if we have only one person without a manager,
    // they become the top most node in our tree
    // otherwise, we use the "company" as our top node
    const tree =
      peopleWithoutManagers.length === 1
        ? new Person(this.people[peopleWithoutManagers[0]])
        : {
          children: [],
          id: 'default',
          fullName: this.companyName
        };

    Object.keys(this.people).forEach(personId => {
      const person = this.people[personId];
      const node = new Person(person);

      const { department, id, hasChild, hasManager, managerId } = node;

      const companyIsTopNode = tree.id === 'default';
      const personIsTopNode = tree.id === id;
      const managerIsInSameDepartment =
        this.people[managerId] && department === this.people[managerId].department_name;

      if (
        (companyIsTopNode && !hasManager && hasChild) ||
        (this.isFiltered && !personIsTopNode && !managerIsInSameDepartment)
      ) {
        tree.children.push(node);
      }
    });

    return tree;
  };

  /*
   * Generate chart from chart data
   */
  renderChart = data => {
    if (data.length === 0) {
      return;
    }
    init({ id: '#orgChart', data: data, lineType: 'angle' });
  };

  /*
   * Rerender the chart with the original data
   */
  reset = () => {
    this.isFiltered = false;
    $(this.resetTarget).hide();

    // deselect the employee filter
    if ($(this.employeeFilterTarget).val()) {
      $(this.employeeFilterTarget)
        .find('option:selected')
        .prop('selected', false);
      this.employeeFilterTarget.dispatchEvent(new Event('change'));
    }
  };

  /*
   * Re-renders the orgchart with a specific person as the top node
   */
  filterChartByEmployee = () => {
    $(this.resetTarget).show();
    this.search(this._chartData, this.employeeId);
  };

  /*
   * Display org chart members who belong to the selected department
   */
  filterChart = event => {
    event.stopImmediatePropagation();

    // Filter chart based on both filter options
    const employeeId = $(this.employeeFilterTarget).val();

    // Employee filter supercedes the Department filter
    // so if the employeeId is not null, we filter by employee
    if (employeeId) {
      $(this.resetTarget).show();
      this.isFiltered = false;
      return this.search(this._chartData, employeeId);
    }

    // If neither filter is selected, we render the original chart data
    return this.renderChart(this._chartData);
  };

  /*
   * Search for an employee by ID
   */
  search = (data, employeeId) => {
    if (!employeeId) return;

    const children = data.children || data._children;
    const nodeId = this.getManagerForEmployeeById(employeeId) || employeeId;

    if (data.id === nodeId) {
      this.renderChart(data);
    } else {
      for (let i = 0; i < children.length; i++) {
        const newData = children[i];
        if (newData.id === nodeId) {

          this.renderChart(newData);
        }
        this.search(newData, employeeId);
      }
    }
  };

  /*
   * Returns the manager ID for the employee
   * given an employee id
   * if employee or manager does not exist, return null
   */
  getManagerForEmployeeById(employeeId) {
    return this.people[employeeId].manager_id;
  }

  get companyId() {
    return this.data.get('companyId');
  }

  get companyName() {
    return this.data.get('companyName');
  }

  get employeeId() {
    return $(this.employeeFilterTarget).val();
  }
}

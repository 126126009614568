import * as Sentry from '@sentry/browser'

const sentry = () => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: 'https://28b48525b0a34d25a2c29435f1902900@o23982.ingest.sentry.io/1319166',
      environment: process.env.NODE_ENV,
      project: 'ease-frontend'
    });
  }
}

export default sentry;
/* eslint-disable no-param-reassign */
import { Controller } from 'stimulus';
import { formContainsErrors } from '../lib/utils';

export default class extends Controller {
  static targets = ['nextButton', 'backButton', 'tab', 'tabNav', 'submitButton', 'navItem'];

  initialize() {
    this.tabTargets.forEach((tab, i) => {
      tab.setAttribute('data-tab-number', i);
    });
    this.setUpButtons();
    this.createTabNav();
    this.activeIndex = 0;
  }

  setUpButtons() {
    const buttonRow = document.createElement('div');
    buttonRow.setAttribute('class', 'c-modal__button-row');
    this.element.appendChild(buttonRow);

    const formId = this.element.id;
    const existingButtons = document.querySelectorAll(`#${formId} button`);

    buttonRow.innerHTML +=
      '<button class="large button stroke" type="button" data-action="click->tabbed-form#handleClickBackButton" data-target="tabbed-form.backButton">Back</button>';

    buttonRow.innerHTML +=
      '<button class="large button" type="button" data-action="click->tabbed-form#handleClickNextButton" data-target="tabbed-form.nextButton">Next</button>';

    existingButtons.forEach(button => {
      button.type = 'button';
      buttonRow.appendChild(button);
    });
  }

  handleClickBackButton() {
    this.activeIndex--;
  }

  handleClickNextButton() {
    // Check if current tab is satisfactory to move on
    const formId = this.element.id;
    if (window.ease.page.requireFormValues(formId) && !formContainsErrors(formId)) {
      this.activeIndex++;
    }
  }

  createTabNav() {
    const modalNav = document.createElement('div');
    modalNav.setAttribute('class', 'c-modal__nav');

    this.tabTargets.forEach((tab, i) => {
      const modalNavTabString = `
        <div class='c-modal__nav-item' data-target='tabbed-form.navItem' data-action='click->tabbed-form#setActiveIndex' data-tab-index='${i}'>
          <div class="c-modal__nav-item-circle">${i + 1}</div>
          <div class="c-modal__nav-item-name">${tab.dataset.tabName}</div>
        <div>
      `;

      modalNav.innerHTML += modalNavTabString;
    });

    this.element.prepend(modalNav);
  }

  setActiveIndex(e) {
    // TODO: do not rely on presentation classes for logic. use either a `.js-` class or another element attribute
    let newIndex = e.target.closest('.c-modal__nav-item').dataset.tabIndex;
    newIndex = parseInt(newIndex, 10);
    const formId = this.element.id;
    if (newIndex > this.activeIndex && (!window.ease.page.requireFormValues(formId) || formContainsErrors(formId))) {
      // Block skipping ahead if there are errors or missing info
      return;
    }
    this.activeIndex = newIndex;
  }

  updateButtons() {
    this.tabTargets.forEach((tab, i) => {
      if (i !== this.activeIndex) {
        tab.classList.add('u-hidden');
      } else {
        tab.classList.remove('u-hidden');
      }
    });

    if (this.activeIndex === 0) {
      this.backButtonTarget.classList.add('u-hidden');
    } else {
      this.backButtonTarget.classList.remove('u-hidden');
    }

    if (this.activeIndex === this.tabTargets.length - 1) {
      this.nextButtonTarget.classList.add('u-hidden');
      this.submitButtonTarget.classList.remove('u-hidden');
    } else {
      this.nextButtonTarget.classList.remove('u-hidden');
      this.submitButtonTarget.classList.add('u-hidden');
    }
  }

  updateNav() {
    this.navItemTargets.forEach((navItem, i) => {
      navItem.classList.remove('c-modal__nav-item--in-progress');
      navItem.classList.remove('c-modal__nav-item--complete');
      if (i < this.activeIndex) {
        navItem.classList.add('c-modal__nav-item--complete');
      } else if (i === this.activeIndex) {
        navItem.classList.add('c-modal__nav-item--in-progress');
      }
    });
  }

  get activeIndex() {
    return parseInt(this.data.get('active-index'), 10);
  }

  set activeIndex(value) {
    this.data.set('active-index', value);
    this.updateButtons();
    this.updateNav();
  }
}

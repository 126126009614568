const animationDuration = 350;
const shouldResize = true;

// Nodes
const nodeWidth = 275;
const nodeHeight = 144;
const nodeSpacing = 18;
const nodePaddingX = 16;
const nodePaddingY = 16;
const avatarWidth = 120;
const initialsContainerHeight = 52;
const avatarSize = Math.sqrt(avatarWidth ** 2 + nodeHeight ** 2);
const reportsBadgeWidth = 34;
const reportsBadgeHeight = 20;
const reportsBadgeVerticalDistance = 12;
const peopleIconHeight = 12;
const peopleIconWidth = 12;

const nodeBorderRadius = 8.5;
const margin = {
  top: 20,
  right: 20,
  bottom: 20,
  left: 20
};

// Lines
const lineType = 'angle';
const lineDepthY = 120; /* Height of the line for child nodes */

// TODO: Library colors may require mapping
// Colors
const backgroundColor = '#FFFFFF';
const borderColor = '#E6E8E9';
const fontColorDark = '#152542';
const reportsColor = '#92A0AD';
const avatarBackgroundColorLight = '#F2F3FA';
const avatarBackgroundColorDark = '#D9DBE9';

const config = {
  peopleIconHeight,
  peopleIconWidth,
  reportsBadgeWidth,
  reportsBadgeHeight,
  reportsBadgeVerticalDistance,
  avatarSize,
  initialsContainerHeight,
  avatarBackgroundColorLight,
  avatarBackgroundColorDark,
  margin,
  animationDuration,
  nodeWidth,
  nodeHeight,
  nodeSpacing,
  nodePaddingX,
  nodePaddingY,
  nodeBorderRadius,
  avatarWidth,
  lineType,
  lineDepthY,
  backgroundColor,
  borderColor,
  fontColorDark,
  reportsColor,
  shouldResize
};

module.exports = config;

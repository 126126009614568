// signature_controller.js
import { Controller } from 'stimulus';
import SignaturePad from 'signature_pad/dist/signature_pad.js'; // eslint-disable-line
import $ from 'jquery';
import { keyCodes } from '../lib/global';
import { isLandscape, isMobile } from '../lib/utils';

export default class extends Controller {
  static targets = [
    'clear',
    'finish',
    'form',
    'forms',
    'input',
    'modal',
    'nextStep',
    'pageCount',
    'previousStep',
    'signatureCount',
    'signature',
    'signaturePad',
    'step',
    'text',
    'enrollerConfirm',
    'modalTitleText',
    'modalSubtitleText',
    'modalTitleSignature',
    'modalSubtitleSignature',
    'modalTitleReview',
    'modalSubtitleReview'
  ];

  initialize() {
    this.element.setAttribute('data-signature-index', '0');
    this.element.setAttribute('data-target', 'signature');
    this.element.setAttribute('data-initialized', 'true');

    this.selectors = {
      currentStep: 'c-signature__step--current',
      signatureTargetSigned: 'c-sign-forms__signature--signed'
    };

    this.signaturesRemainingLabel = this.signaturesRemaining;
    this.totalPages = this.totalPages;

    window.addEventListener('resize', this.resizeCanvas);
    window.addEventListener('orientationchange', this.resizeCanvas);

    if (this.hasInputTarget) {
      this.signaturePad = new SignaturePad(this.signaturePadTarget, {
        minWidth: 1.0,
        maxWidth: 2.5,
        penColor: 'rgb(21, 37, 66)',
        onEnd: this.drawingDidEnd
      });
    }

    this.checkDeviceOrientation();
    this.showCurrentStep();
  }

  previousStep() {
    switch (this.index) {
      case 1:
      case 2:
        this.index--;
        break;
      default:
        break;
    }

    this.checkDeviceOrientation();
  }

  nextStep() {
    switch (this.index) {
      case 0:
        if (this.hasInputTarget) {
          if ($(this.inputTarget).val() === '') {
            $(this.inputTarget).focus();
            return;
          }
          this.index++;
        } else {
          // Voice signature only has 2 steps so skip ahead
          this.index += 2;
        }
        break;
      case 1:
        if (this.hasInputTarget) {
          this.saveSignature();
        }
        this.index++;
        break;
      case 2: {
        this.closeSignatureModal();
        this.scrollToNextUnsignedTarget();
        this.updateButtonVisibility([this.nextStepTargets, this.previousStepTargets], 'none');
        if (this.isFinishedSigning) {
          $(this.finishTarget).css('display', 'inline');
        }
        break;
      }
      default:
        break;
    }

    this.checkDeviceOrientation();
  }

  showCurrentStep() {
    this.stepTargets.forEach((el, i) => {
      el.classList.toggle(this.selectors.currentStep, this.index === i);
    });

    switch (this.index) {
      case 0:
        this.modalTitle = $(this.modalTitleTextTarget).val();
        this.modalSubtitle = $(this.modalSubtitleTextTarget).val();

        this.updateButtonVisibility(this.nextStepTargets, 'inline');
        this.updateButtonVisibility(this.previousStepTargets, 'none');

        if (this.hasInputTarget) {
          this.validateTypedSignature();

          setTimeout(() => {
            $(this.inputTarget).focus();
          }, 50);
        } else {
          // Voice confirmation needed before Next is visible
          this.nextStepIsVisible = false;
        }
        break;
      case 1:
        if (this.hasInputTarget) {
          this.modalTitle = $(this.modalTitleSignatureTarget).val();
          this.modalSubtitle = $(this.modalSubtitleSignatureTarget).val();

          this.updateButtonVisibility(this.previousStepTargets, 'inline');
          this.nextStepIsVisible = false;
          this.showSignaturePad();
        }
        break;
      case 2:
        this.modalTitle = $(this.modalTitleReviewTarget).val();
        this.modalSubtitle = $(this.modalSubtitleReviewTarget).val();
        break;
      default:
        break;
    }
  }

  updateButtonVisibility(targets, displayProperty) {
    $(targets).each(() => $(this).css('display', displayProperty));
  }

  showSignaturePad() {
    this.resizeCanvas();
  }

  keydown(event) {
    if (event.keyCode === keyCodes.enter) {
      event.preventDefault();
      this.nextStep();
    }
  }

  closeSignatureModal() {
    this.closeModal();
  }

  scrollToNextUnsignedTarget() {
    const nextSignatureTarget = this.getNextSignatureTarget();
    const offset = this.getSignatureTargetOffset(nextSignatureTarget);

    this.scrollTo(offset);
  }

  validateTypedSignature() {
    this.nextStepIsVisible = $(this.inputTarget).val() !== '';
  }

  finishSigning() {
    // finish tapped
    ease.SignForms.finish(this.signature);
  }

  apply = event => {
    if (typeof $(event.currentTarget).data('isSigned') !== 'undefined') {
      return;
    }

    const $target = $(event.currentTarget);
    const signature = $('<img />', {
      src: this.signature,
      class: 'c-sign-forms__signature-image'
    });

    $target.append(signature);
    $target.addClass(this.selectors.signatureTargetSigned);

    this.scrollToNextUnsignedTarget();

    if (this.isFinishedSigning) {
      $(this.finishTarget).css('display', 'inline');
    }

    this.signaturesRemainingLabel = this.signaturesRemaining;

    $(event.currentTarget).data('isSigned', true);
  };

  getNextSignatureTarget = () => {
    const { selectors } = this;

    for (let i = 0; i < this.signatureTargets.length; i++) {
      const targetAtIndex = this.signatureTargets[i];

      if (!$(targetAtIndex).hasClass(selectors.signatureTargetSigned)) {
        return targetAtIndex;
      }
    }

    return null;
  };

  scrollTo = offset => {
    $(this.formsTarget).animate({ scrollTop: offset }, 750);
  };

  getSignatureTargetOffset = target => {
    const formMargin = 24;
    const halfVisibleHeight = window.innerHeight / 2.0;
    const forms = this.formTargets;

    let offset = 0;

    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      const formHeight = $(form).height();
      const $signatureTargets = $(form).find('.c-sign-forms__signature');

      for (let j = 0; j < $signatureTargets.length; j++) {
        if ($signatureTargets.get(j) === target) {
          offset += $(target).position().top;
          offset -= halfVisibleHeight;
          return offset;
        }
      }

      offset += formHeight + formMargin;
    }

    return 0;
  };

  drawingDidEnd = () => {
    // Validate signature is not just a dot
    if (this.signaturePad.isEmpty() || this.signaturePad.toDataURL().length < 2500) {
      return;
    }

    this.nextStepIsVisible = true;
    this.saveSignature();

    $(this.clearTarget).show();
  };

  checkDeviceOrientation = () => {
    $('html').toggleClass('mobile landscape', isMobile() && isLandscape());
  };

  saveSignature = () => {
    this.signature = this.cropSignatureCanvas;
  };

  clearSignature = () => {
    this.signaturePad.clear();
    this.nextStepIsVisible = false;
    $(this.clearTarget).hide();
  };

  resizeCanvas = () => {
    this.checkDeviceOrientation();

    const $signatureContainer = $('.c-signature');
    this.signaturePadTarget.width = $signatureContainer.width();
    this.signaturePadTarget.height = $signatureContainer.height();

    if (this.signaturePad) this.clearSignature();
  };

  closeModal() {
    $('.c-modal').addClass('fadeOutTop');

    setTimeout(() => {
      $('.c-modal').removeClass('fadeInTop fadeOutTop');
    }, 200);
  }

  checkConfirmation = () => {
    this.nextStepIsVisible = this.enrollerConfirmationValue === true;
  };

  get cropSignatureCanvas() {
    // Duplicate canvas
    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');

    croppedCanvas.width = this.signaturePadTarget.width;
    croppedCanvas.height = this.signaturePadTarget.height;
    croppedCtx.drawImage(this.signaturePadTarget, 0, 0);

    // Crop canvas
    let { width, height } = croppedCanvas;

    const pix = {
      x: [],
      y: []
    };
    const imageData = croppedCtx.getImageData(0, 0, croppedCanvas.width, croppedCanvas.height);
    let x;
    let y;
    let index;

    for (y = 0; y < height; y++) {
      for (x = 0; x < width; x++) {
        index = (y * width + x) * 4;
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x);
          pix.y.push(y);
        }
      }
    }

    pix.x.sort((a, b) => a - b);
    pix.y.sort((a, b) => a - b);
    const n = pix.x.length - 1;

    width = pix.x[n] - pix.x[0];
    height = pix.y[n] - pix.y[0];
    const cut = croppedCtx.getImageData(pix.x[0], pix.y[0], width, height);

    croppedCanvas.width = width;
    croppedCanvas.height = height;
    croppedCtx.putImageData(cut, 0, 0);

    return croppedCanvas.toDataURL('image/svg+xml');
  }

  set nextStepIsVisible(value) {
    $(this.nextStepTargets).each((index, el) => $(el).prop('disabled', !value));
  }

  get index() {
    return Number(this.data.get('index'));
  }

  set index(value) {
    this.data.set('index', value);
    this.showCurrentStep();
  }

  set modalTitle(value) {
    $('.c-modal__title').text(value);
  }

  set modalSubtitle(value) {
    $('.c-modal__subtitle').text(value);
  }

  get isFinishedSigning() {
    return (
      this.signatureTargets.length === 0 ||
      this.signatureTargets.every(el => el.classList.contains(this.selectors.signatureTargetSigned))
    );
  }

  get totalPages() {
    return this.formTargets.length;
  }

  set totalPages(value) {
    this.pageCountTarget.textContent = `(${value} pages)`;
  }

  get signaturesRemaining() {
    return this.signatureTargets.filter(
      el => !el.classList.contains(this.selectors.signatureTargetSigned)
    ).length;
  }

  set signaturesRemainingLabel(value) {
    this.signatureCountTarget.textContent = value;
  }

  get enrollerConfirmationValue() {
    return $(this.enrollerConfirmTarget)[0].checked;
  }
}

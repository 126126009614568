import { Controller } from 'stimulus';
import $ from 'jquery';
import debounce from 'lodash.debounce';
import { keyCodes } from '../lib/global';

export default class extends Controller {
  static targets = ['field', 'results', 'query', 'clear'];

  initialize() {
    this.showSearchResults = 'c-modal--search-results';
    this.search = debounce(this.search, 300);
    this.cache = {};
    this.queryRoute = '/v1/search';
  }

  preventLeadingSpaces = event => {
    if (event.target.value === '' && event.keyCode === keyCodes.spacebar) {
      event.preventDefault();
    }
  };

  clearSearch = () => {
    $(this.fieldTarget).val('');
    $(this.element).toggleClass(this.showSearchResults, false);
    $(this.clearTarget).toggleClass('is-enabled', false);
    $(this.fieldTarget).focus();

    this.hideResults();
  };

  search = event => {
    const query = event.target.value;

    if (query === '') {
      this.clearSearch();
      return;
    }

    if (query.length < 2) {
      $(this.queryTarget).text('Type at least 2 characters to search...');
      $(this.element).toggleClass(this.showSearchResults, true);
      this.hideResults();
      return;
    }

    $(this.queryTarget).text(`Searching for "${query}"...`);
    $(this.element).toggleClass(this.showSearchResults, true);
    $(this.clearTarget).toggleClass('is-enabled', true);

    if (query in this.cache) {
      this.showResults(this.cache[query], query);
      return;
    }

    $.get(this.queryRoute, { term: query }, data => {
      this.cache[query] = data;

      if (data.term && data.term !== $(this.fieldTarget).val()) {
        return;
      }

      this.showResults(data, query);
    });
  };

  showResults = (data, query) => {
    this.hideResults();

    const { items } = data;

    // Determine whether the query contains terms less than 2 characters which we ignore
    const hasIgnoredTerms = query.split(' ').some(term => term !== '' && term.length < 2);

    const queryMessage = hasIgnoredTerms
      ? `Showing top ${items.length} matches for "${query}" (ignoring term(s) less than 2 characters)`
      : `Showing top ${items.length} matches for "${query}"`;

    $(this.queryTarget).text(queryMessage);

    if (items.length === 0) return;

    $(this.clearTarget).show();

    items.forEach(result => {
      $('<li>', {
        class: 'c-search-result',
        html: this.getSearchResult(result)
      }).appendTo($(this.resultsTarget));
    });
  };

  getResultContent = result => {
    const display = $('<div />', {
      class: 'c-search-result__display'
    });

    const type = result.type.toLowerCase();

    const icon =
      type.startsWith('client') ||
      type.startsWith('broker') ||
      type.startsWith('partner') ||
      type.startsWith('company')
        ? this.CompanyIcon
        : this.getEmployeeAvatar(result);

    const name = $('<a />', {
      href: result.url,
      text: result.name,
      class: 'c-search-result__name'
    });

    display.append(icon);
    display.append(name);

    return display;
  };

  getResultContext = result => {
    const context = $('<div />', {
      class: 'c-search-result__context'
    });

    // type
    const type = $('<label />', {
      class: 'c-search-result__context-label',
      text: 'TYPE'
    });

    const typeValue = $('<span />', {
      class: 'c-search-result__context-value',
      text: result.type.charAt(0).toUpperCase() + result.type.slice(1)
    });

    context.append(type);
    context.append(typeValue);

    if (result.company_subdomain) {
      // subdomain
      const t = type.clone().text('SUBDOMAIN');
      const v = typeValue.clone().text(`${result.company_subdomain}`);

      context.append(t);
      context.append(v);
    }

    if (result.ssn) {
      // ssn
      const t = type.clone().text('SSN');
      const v = typeValue.clone().text(`${result.ssn}`);

      context.append(t);
      context.append(v);
    }

    if (result.company) {
      // company
      const t = type.clone().text('COMPANY');
      const v = typeValue.clone().text(result.company);

      context.append(t);
      context.append(v);
    }
    return context;
  };

  getSearchResult = result => {
    const container = $('<div />', {
      class: 'c-search-result__container'
    });

    const display = this.getResultContent(result);
    const context = this.getResultContext(result);

    container.append(display);
    container.append(context);

    return container;
  };

  hideResults = () => {
    $(this.resultsTarget).html('');

    if ($(this.fieldTarget).val() === '') {
      $(this.clearTarget).hide();
    }
  };

  getEmployeeAvatar(result) {
    const avatar = $('<div />', {
      class: 'c-avatar',
      style: result.color ? `background-color: ${result.color}` : null
    });

    if (result.avatar) {
      $('<img />', {
        class: 'c-avatar__image',
        src: result.avatar
      }).appendTo(avatar);
    } else {
      $('<span />', {
        class: 'c-avatar__initials',
        text: result.initials
      }).appendTo(avatar);
    }

    return avatar;
  }

  // CLEANUP: is this the best way to get this asset? Lucas 2/13/19
  get CompanyIcon() {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const svgns = 'http://www.w3.org/2000/svg';
    const use = document.createElementNS(svgns, 'use');

    use.setAttribute('href', '#icon_company-avatar');
    svg.appendChild(use);
    svg.setAttribute('class', 'c-search-result__icon');

    return svg;
  }
}

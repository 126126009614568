// comment_controller.js
import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['saveOrCancel', 'editOrDelete', 'comment', 'mutableComment'];

    updateTextAreaHeight() {
      const $commentHeight = $(this.commentTarget).height();
      $(this.mutableCommentTarget).height($commentHeight);
    }

    showSaveCancel() {
      const $saveOrCancel = $(this.saveOrCancelTarget);
      const $editOrDelete = $(this.editOrDeleteTarget);

      // remove previous animations
      $editOrDelete.removeClass('fadeInRight');
      $saveOrCancel.removeClass('fadeOutLeft');

      // add new animations
      $editOrDelete.addClass('fadeOutRight');
      $saveOrCancel.addClass('fadeInLeft');
    }

    showEditDelete() {
      const $saveOrCancel = $(this.saveOrCancelTarget);
      const $editOrDelete = $(this.editOrDeleteTarget);

      // remove previous animations
      $editOrDelete.removeClass('fadeOutRight');
      $saveOrCancel.removeClass('fadeInLeft');

      // add new animations
      $editOrDelete.addClass('fadeInRight');
      $saveOrCancel.addClass('fadeOutLeft');
    }

    save(event) {
      event.preventDefault();

      const $commentCard = $(this.element);
      const $comment = $(this.commentTarget);
      const $mutableComment = $(this.mutableCommentTarget);

      $comment.html($mutableComment.val());
      $commentCard.removeClass('edit');

      this.showEditDelete();
    }

    cancel(event) {
      event.preventDefault();

      const $commentCard = $(this.element);
      const $existingComment = $(this.commentTarget);
      const $originalComment = this.comment;

      $commentCard.removeClass('edit');
      $existingComment.val($originalComment);
      $existingComment.attr('disabled', 'true');

      this.showEditDelete();
    }

    edit(event) {
      event.preventDefault();

      const $commentCard = $(this.element);
      const $existingComment = $(this.mutableCommentTarget);
      this.comment = $existingComment.val();

      this.updateTextAreaHeight();

      $commentCard.addClass('edit');
      $existingComment.focus();

      this.showSaveCancel();
    }

    delete(event) {
      event.preventDefault();

      const $commentCard = $(this.element);
      $commentCard.remove();
    }

    set comment(value) {
      this.data.comment = value;
    }

    get comment() {
      return this.data.comment;
    }
}

import { Controller } from 'stimulus';
import $ from 'jquery';
import { keyCodes } from '../lib/global';

export default class extends Controller {
  static targets = ['checkbox'];

  keydown(event) {
    if (event.keyCode === keyCodes.enter) {
      this.toggle();
    }
  }

  toggle() {
    const checked = !$(this.checkboxTarget).is(':checked');
    $(this.checkboxTarget).prop('checked', checked);
  }
}

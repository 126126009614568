import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  initialize() {
    const finished = parseInt($(this.element).attr('data-finished'), 10) || 0;
    const inProgress = parseInt($(this.element).attr('data-in-progress'), 10) || 0;
    const notStarted = parseInt($(this.element).attr('data-not-started'), 10) || 0;
    const testing = parseInt($(this.element).attr('data-testing'), 10) || 0;
    const pending = parseInt($(this.element).attr('data-pending'), 10) || 0;
    const total = finished + inProgress + notStarted + testing + pending;
    const showPercentComplete = $(this.element).attr('data-show-percentage') === 'true';

    if (finished > 0) {
      this.updateProgress(finished, total, 'c-progress-bar__finished', !showPercentComplete);
    }

    if (inProgress > 0) {
      this.updateProgress(inProgress, total, 'c-progress-bar__in-progress', !showPercentComplete);
    }

    if (notStarted > 0) {
      this.updateProgress(notStarted, total, 'c-progress-bar__not-started', !showPercentComplete);
    }

    if (testing > 0) {
      this.updateProgress(testing, total, 'c-progress-bar__testing', !showPercentComplete);
    }

    if (pending > 0) {
      this.updateProgress(pending, total, 'c-progress-bar__pending', !showPercentComplete);
    }

    if (showPercentComplete) {
      const $label = $('<div />', {
        class: 'c-progress-bar__percentage',
        html: finished > 0 && total > 0 ? `${((finished / total) * 100).toFixed(0)}%` : '0%'
      });
  
      $label.prependTo($(this.element));
    }
  }

  updateProgress(sum, total, status, showLabel) {
    const $statusBar = $('<div />', {
      class: `c-progress-bar__item ${status}`,
      css: {
        width: `${(sum / total) * 100}%`
      }
    });

    if (showLabel === true) {
      const $label = $('<div />', {
        class: 'c-progress-bar__count',
        html: sum
      });
  
      $label.appendTo($statusBar);        
    }

    $statusBar.appendTo($(this.element));

    if (sum < 10) {
      $statusBar.css('min-width', '45px');
    } else if (sum < 100) {
      $statusBar.css('min-width', '54px');
    } else if (sum < 1000) {
      $statusBar.css('min-width', '63px');
    } else if (sum < 10000) {
      $statusBar.css('min-width', '72px');
    }
  }
}

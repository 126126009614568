import d3 from 'd3';
import collapseNode from './utils/collapse';
import defineBoxShadow from './defs/box-shadow';
import defineAvatarClip from './defs/avatar-clip';
import render from './render';
import renderUpdate from './render-update';
import defaultConfig from './config';

export default function init(options) {
    // Merge options with the default config
    const config = {
        ...defaultConfig,
        ...options,
        treeData: options.data
    };

    if (!config.id) return;

    const {
        id,
        treeData,
        lineType,
        margin,
        nodeWidth,
        nodeHeight,
        nodeSpacing,
        shouldResize
    } = config;

    // Calculate how many pixel nodes to be spaced based on the
    // type of line that needs to be rendered
    if (lineType === 'angle') {
        config.lineDepthY = nodeHeight + 40;
    } else {
        config.lineDepthY = nodeHeight + 60;
    }

    // Get the root element
    const elem = document.querySelector(id);

    if (!elem) return;

    // Reset in case there's any existing DOM
    elem.innerHTML = '';

    const elemWidth = elem.offsetWidth;
    const elemHeight = elem.offsetHeight;

    // Setup the d3 tree layout
    config.tree = d3.layout.tree().nodeSize([nodeWidth + nodeSpacing, nodeHeight + nodeSpacing]);

    const children = treeData.children || treeData._children;

    // Add svg root for d3
    const svgroot = d3
        .select(id)
        .append('svg')
        .attr('width', elemWidth)
        .attr('height', elemHeight);

    const translateX = parseInt((elemWidth - nodeWidth) / 2, 10);

    // Add our base svg group to transform when a user zooms/pans
    const svg = svgroot.append('g').attr('transform', `translate(${translateX}, 20)`);

    // svg definitions
    defineBoxShadow(svgroot, 'boxShadow');
    defineAvatarClip(svgroot, 'avatarClip', {
        borderRadius: 8.5,
        avatarWidth: config.avatarWidth,
        nodeHeight: config.nodeHeight
    });

    // Center the viewport on initial load
    treeData.x0 = 0;
    treeData.y0 = elemHeight / 2;

    if (children) {
        if (treeData._children) {
            treeData.children = treeData._children;
        }

        children.forEach(child => {
            collapseNode(child);
        });
    }

    // Connect core variables to config so that they can be
    // used in internal rendering functions
    config.svg = svg;
    config.svgroot = svgroot;
    config.render = render;

    // Defined zoom behavior
    const zoom = d3.behavior
        .zoom()
        // Define the [zoomOutBound, zoomInBound]
        .scaleExtent([0.4, 2])
        .duration(50)
        .on('zoom', renderUpdate(config));

    // Attach zoom behavior to the svg root
    svgroot.call(zoom);

    // Define the point of origin for zoom transformations
    zoom.translate([translateX, 20]);

    // Add listener for when the browser or parent node resizes
    const resize = () => {
        if (!elem) {
            global.removeEventListener('resize', resize);
            return;
        }

        svgroot.attr('width', elem.offsetWidth).attr('height', elem.offsetHeight);
    };

    if (shouldResize) {
        global.addEventListener('resize', resize);
    }

    // Start initial render
    render(config);

    // Update DOM root height
    d3.select(id).style('height', elemHeight + margin.top + margin.bottom);
}

// plan-comparison_controller.js
import $ from 'jquery';
import { Controller } from 'stimulus';
import throttle from 'lodash.throttle';
import window from '../lib/window';

export default class extends Controller {
  static targets = [
    'modal',
    'points',
    'plans',
    'plan',
    'planName',
    'planSummary',
    'previous',
    'next'
  ];

  initialize() {
    const self = this;
    this.constants = {
      smallScreenBreakpoint: 767,
      planComparisonPadding: 16,
      maxPlanNameCharacterLength: 30
    };

    this.classNames = {
      fadeInLeft: 'fade-in-left',
      fadeOutLeft: 'fade-out-left',
      fadeInRight: 'fade-in-right',
      fadeOutRight: 'fade-out-right',
      isMobile: 'c-plan-comparison--is-mobile',
      column: 'c-plan-comparison__column'
    };

    $(this.plansTarget).on(
      'scroll',
      throttle(() => {
        self.toggleShadow();
      }, 20)
    );

    $(window).on(
      'resize',
      throttle(() => {
        self.resize();
      }, 20)
    );

    this.resize();
    this.index = 0;
  }

  closePlanComparisonModal = () => {
    this.closeModal();
  };

  toggleShadow() {
    const scrollPosition = $(this.plansTarget).scrollLeft();
    $(this.pointsTarget).toggleClass('is-scrolled', scrollPosition > 0);
  }

  resize = () => {
    const isSmall = $(window).innerWidth() < this.constants.smallScreenBreakpoint;

    $(this.element).toggleClass(this.classNames.isMobile, isSmall);

    const componentWidth = $(window).innerWidth() - this.constants.planComparisonPadding * 2;

    $(this.planSummaryTargets).each((index, element) => {
      isSmall ? $(element).css('left', -componentWidth / 2) : $(element).css('left', 'initial');
    });

    $(this.previousTarget).toggle(isSmall);
    $(this.nextTarget).toggle(isSmall);
  };

  showCurrentPlan() {
    this.planTargets.forEach((el, i) => {
      $(el).removeClass(`
                ${this.classNames.fadeOutLeft}
                ${this.classNames.fadeOutRight}
                ${this.classNames.fadeInLeft}
                ${this.classNames.fadeInRight}
            `);

      const previousPlanIndex = i - 1 < 0 ? this.numberOfPlans : i - 1;
      const nextPlanIndex = i + 1 > this.numberOfPlans ? 0 : i + 1;

      switch (this.direction) {
        case 'backward':
          $(el).toggleClass(this.classNames.fadeOutRight, this.index === previousPlanIndex);
          $(el).toggleClass(this.classNames.fadeInLeft, this.index === i);
          break;
        case 'forward':
          $(el).toggleClass(this.classNames.fadeOutLeft, this.index === nextPlanIndex);
          $(el).toggleClass(this.classNames.fadeInRight, this.index === i);
          break;
        default:
          $(el).toggleClass(this.classNames.fadeInLeft, this.index === i);
          break;
      }
    });
  }

  previousPlan() {
    this.direction = 'backward';

    if (this.index === 0) {
      this.index = this.numberOfPlans;
    } else {
      this.index--;
    }
  }

  nextPlan() {
    this.direction = 'forward';

    if (this.index === this.numberOfPlans) {
      this.index = 0;
    } else {
      this.index++;
    }
  }

  closeModal() {
    $('.c-modal').addClass('fadeOutTop');

    setTimeout(() => {
      $('.c-modal').removeClass('fadeInTop fadeOutTop');
    }, 200);
  }

  get index() {
    return Number(this.data.get('index'));
  }

  set index(value) {
    this.data.set('index', value);
    this.showCurrentPlan();
  }

  get numberOfPlans() {
    return $(this.planTargets).length - 1;
  }
}

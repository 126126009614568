// overflow_controller.js
import $ from 'jquery';
import debounce from 'lodash.debounce';
import { Controller } from 'stimulus';
import window from '../lib/window';
import { isIE11 } from '../lib/utils';

export default class extends Controller {
  static targets = ['overflow', 'position'];

  initialize() {
    this.isIE11 = isIE11();
    $.expr.filters.overflowingy = el => {
      const rect = el.getBoundingClientRect();
      const maxY = rect.y + rect.height;
      return maxY > window.innerHeight;
    };

    const self = this;

    $(window).on(
      'resize',
      debounce(() => {
        self.checkOverflow();
      }, 200)
    );

    this.checkOverflow();
  }

  checkOverflow() {
    const self = this;

    if (!this.isIE11) {
      if ($(this.overflowTarget).is(':overflowingy')) {
        $(self.positionTarget).removeClass('c-sidebar__body--fixed');
      } else {
        $(self.positionTarget).addClass('c-sidebar__body--fixed');
      }
    }
  }
}

// folder_controller.js
import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  toggle(event) {
    if (event.target.tagName.toLowerCase() === 'a') return;

    const $folder = $(this.element);
    const $files = $(`.c-document-library__directory-item.${$folder.attr('id')}`);

    $files.each(function() {
      $(this).toggle();
    });

    if ($folder.hasClass('is-closed')) {
      $folder.removeClass('is-closed').addClass('is-open');
    } else {
      $folder.removeClass('is-open').addClass('is-closed');
    }
  }
}

import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  initialize() {
    const { pageViewKey } = this;

    if (!localStorage.getItem(pageViewKey)) localStorage.setItem(pageViewKey, 'list');
    const currentViewType = localStorage.getItem(pageViewKey);

    $(`div.js-${currentViewType}-view`).removeClass('u-hidden');
    $(`div.toggle-${currentViewType}`).addClass('active');
  }

  setViewType(event) {
    const target = $(event.currentTarget);
    const key = target.attr('data-key');
    const value = target.attr('data-value');

    if (!target.hasClass('active')) {
      this.swapActiveClass(target);
      this.swapBodyContent();
      this.setLocalStorage(key, value);
    }
  }

    setLocalStorage = (key, value) => {
      localStorage.setItem(key, value);
    };

    swapActiveClass = target => {
      target.addClass('active');
      target.siblings('.c-toggle-view__tab').removeClass('active');
    };

    swapBodyContent = () => {
      $('div.js-list-view').toggleClass('u-hidden');
      $('div.js-card-view').toggleClass('u-hidden');
    };

    get pageViewKey() {
      return this.data.get('pageViewKey');
    }
}

// modal_controller.js
import { Controller } from 'stimulus';
import $ from 'jquery';
import { keyCodes } from '../lib/global';

export default class extends Controller {
  static targets = ['title', 'save', 'delete', 'file', 'background'];

  initialize() {
    const modal = this.element;
    const id = $(modal).attr('id');

    // Ensure expected buttons exist so they can accept data-targets
    this.addButton(modal, 'Delete');
    this.addButton(modal, 'Button');

    // Ensure existing buttons have appropriate data-targets
    $(modal)
      .find(`#${id}Delete`)
      .attr('data-target', 'modal.delete');
    $(modal)
      .find(`#${id}Button`)
      .attr('data-target', 'modal.save');

    if ($(this.element).attr('data-initialize')) {
      this.open({ target: modal });
    }
  }

  addButton(modal, type) {
    const id = `${$(modal).attr('id')}${type}`;
    const button = $(modal).find(`#${id}`);

    if (!button.length) {
      $(modal)
        .find('.c-modal__footer')
        .append(`<input type='hidden' class='button' id='${id}'></input>`);
    }
  }

  hideAll() {
    $('.fadeInTop').each(function () {
      $(this)
        .closest('div.c-modal')
        .removeClass('fadeInTop');
    });
  }

  chooseFile() {
    $(this.fileTarget).click();
  }

  open(event) {
    document.addEventListener('keydown', this.keyDownEvent);

    this.hideAll(event);

    const id = $(event.target).attr('data-reveal');
    const $modal = id ? $(`#${id}Modal`) : $(event.target);

    if ($modal && $modal.length) {
      $('body').addClass('modal-open');
      $modal.addClass('fadeInTop');

      // focus if searchable
      this.searchInput.focus();
    }
  }

  close(event) {
    if (event && event.target && event.target.dataset && event.target.dataset.method === 'cancel') {
      event.target.dispatchEvent(new Event('cancel'));
    }

    // Pause any contained YouTube videos.
    $('[id^=youtubevideo_]').each(function () { $(this)[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*') });

    $('body').removeClass('modal-open');
    $('.c-modal').addClass('fadeOutTop');

    setTimeout(() => {
      $('.c-modal').removeClass('fadeInTop fadeOutTop');
    }, 100);

    document.removeEventListener('keydown', this.keyDownEvent);
  }

  handleBackgroundClick(event) {
    if (event.target.dataset.target === 'modal.background') {
      this.close(event);
    }
  }

  clear(event) {
    // Hide any visible modals
    this.hideAll(event);

    const $target = $(event.target);
    const $titleTarget = $(this.titleTarget);
    const $saveTarget = $(this.saveTarget);
    const $deleteTarget = $(this.deleteTarget);

    const id = `${$target.attr('data-reveal')}Modal`;

    $(`#${id}`)
      .find(':input')
      .each(function () {
        if (
          $(this).attr('name') != null &&
          $(this)
            .attr('name')
            .startsWith('_')
        )
          return;
        if ($(this).attr('type') === 'checkbox') {
          $(this).prop('checked', false);
        } else if ($(this).is('select')) {
          const first = $(this).find('option:first');
          const isMulti = typeof $(this).attr('multiple') !== 'undefined';
          if (first != null && !isMulti) {
            $(this).val(first.val());
          } else {
            $(this).val('');
          }
          $(this)
            .get(0)
            .dispatchEvent(new Event('change'));
        } else {
          $(this).val('');
        }
      });

    $deleteTarget.hide();
    $saveTarget.show();

    const title = $target.attr('data-title');
    if (title) {
      $titleTarget.text(title);
    }

    const button = $target.attr('data-button');
    if (button) {
      $saveTarget.text(button);
    }

    this.open(event);
  }

  clearSearch = () => {
    const $input = this.searchInput;

    if ($input.length) {
      $input.val('');
      $input.get(0).dispatchEvent(new Event('keyup'));
      $input.focus();
    }
  };

  set(event) {
    // Hide any visible modals
    this.hideAll(event);

    const $target = $(event.target);
    const $titleTarget = $(this.titleTarget);
    const $saveTarget = $(this.saveTarget);
    const $deleteTarget = $(this.deleteTarget);

    const data = JSON.parse($target.val());

    $.each(data.data, function () {
      const $elem = $(`#${this.key}`);
      if ($elem.attr('type') === 'checkbox') {
        $elem.prop('checked', this.value.toLowerCase() === 'true');
      } else if ($elem.is('select')) {
        if ($elem.prop('multiple')) {
          const vals = this.value;
          $(`#${this.key} > option`).each(function () {
            $(this).prop('selected', $.inArray(this.value, vals) > -1);
          });
        } else {
          $elem.val(this.value);
        }
        $elem.get(0).dispatchEvent(new Event('change'));
      } else if ($elem.hasClass('static-value')) {
        $elem.text(this.value);
      } else {
        $elem.val(this.value);
      }
    });

    // Pause any contained YouTube videos.
    $('[id^=youtubevideo_]').each(function () { $(this)[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*') });

    const deleteHidden = $target.attr('data-delete-hidden');
    if (deleteHidden) {
      $deleteTarget.hide();
    } else {
      $deleteTarget.show();
    }

    const title = $target.attr('data-title');
    if (title) {
      $titleTarget.text(title);
    }

    const button = $target.attr('data-button');
    if (button) {
      $saveTarget.text(button);
    }

    this.open(event);
  }

  trigger(event) {
    switch ($(event.target).attr('data-trigger-action')) {
      case 'clear':
        return this.clear(event);
      case 'close':
        return this.close(event);
      case 'open':
        return this.open(event);
      case 'set':
        return this.set(event);
      default:
        return false;
    }
  }

  keyDownEvent = event => {
    const { isVisible } = this;

    if (isVisible && event.keyCode === keyCodes.escape) {
      this.close(event);
    }
  };

  get isVisible() {
    return this.element.classList.contains('fadeInTop');
  }

  get searchInput() {
    return $('.c-search__input', this.element);
  }

  stopPropagation = event => {
    event.stopPropagation();
  };
}
